import React, { useState } from "react";
import "./Projects.css";
import Icon1 from "../../Assests/Icons/Star.svg";
import Icon2 from "../../Assests/Icons/Hands.svg";
import Icon3 from "../../Assests/Icons/wallet.svg";
import Icon4 from "../../Assests/Icons/cart.svg";

const ProjectsHeroSection = ({ setCategory }) => {
  const [selectedCategory, setSelectedCategory] = useState("FEATURED");

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setCategory(category); // Pass selected category to parent component
    // console.log(category);
  };

  return (
    <div className=" text-[#FFFFFF] font-int">
      {/* Main Div */}
      <div className="flex flex-col font-robo pt-[3%]">
        <div className="flex flex-col gap-6 xsm:gap-2 md:gap-4 md:px-[5%]">
          <div>
            <p className="text-center font-bold text-[30px] xsm:text-[18px] md:text-[30px]">
              EXPLORE OUR PORTFOLIO
            </p>
          </div>
          <div>
            <p className="text-center font-extrabold text-[50px] capitalize leading-none xsm:text-[22px] md:text-[36px]">
            Diverse Partnerships, Unified Solutions
            </p>
            <p className="text-center text-[22px] px-[5%] pt-[2%] leading-6 xsm:text-[11px] md:text-[18px]">
            From nonprofits to startups, corporate clients to consumers, we deliver specialized digital solutions that drive success
            </p>
          </div>
        </div>

          <div className="body my-[4%]">
            <div className="tabs">
              <input
                checked={selectedCategory === "FEATURED"}
                onChange={() => handleCategoryChange("FEATURED")}
                value="FEATURED"
                name="fav_language"
                id="FEATURED"
                type="radio"
                className="input"
              />
              <label htmlFor="FEATURED" className="uppercase label flex flex-col gap-6 justify-end xsm:text-[6px] xsm:gap-4 md:gap-4 md:text-[14px]">
              <img src={Icon1} className="xsm:w-5 xsm:h-5 md:w-10 md:h-10" />
              NON Profits
              </label>
              <input
                checked={selectedCategory === "B2B"}
                onChange={() => handleCategoryChange("B2B")}
                value="B2B"
                name="fav_language"
                id="B2B"
                type="radio"
                className="input"
              />
              <label htmlFor="B2B" className="uppercase label flex flex-col gap-6 justify-center xsm:text-[6px] xsm:gap-4 md:gap-4 md:text-[14px]">
              <img src={Icon2} className="xsm:w-5 xsm:h-5 md:w-12 md:h-10" />
              Startups
              </label>
              <input
                checked={selectedCategory === "B2C"}
                onChange={() => handleCategoryChange("B2C")}
                value="B2C"
                name="fav_language"
                id="B2C"
                type="radio"
                className="input"
              />
              <label htmlFor="B2C" className="uppercase label flex flex-col gap-6 justify-center xsm:text-[6px] xsm:gap-4 md:gap-4 md:text-[14px]">
              <img src={Icon3} className="xsm:w-5 xsm:h-5 md:w-10 md:h-10" />
              Corporates
              </label>
              <input
                checked={selectedCategory === "ECOMMERCE"}
                onChange={() => handleCategoryChange("ECOMMERCE")}
                value="ECOMMERCE"
                name="fav_language"
                id="ECOMMERCE"
                type="radio"
                className="input"
              />
              <label htmlFor="ECOMMERCE" className="uppercase label flex flex-col gap-6 justify-center xsm:text-[6px] xsm:gap-4 md:gap-4 md:text-[14px] ">
              <img src={Icon4} className="xsm:w-5 xsm:h-5 md:w-10 md:h-10" />
              SMBs
              </label>
            </div>
          </div>
        </div>
      </div>
  );
};

export default ProjectsHeroSection;
