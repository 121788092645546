import React from "react";
import './OurServices.css'
import { Link } from "react-router-dom";
import Img1 from "../../Assests/Images/ser1.png";
import Img2 from "../../Assests/Images/ser2.png";
import Img3 from "../../Assests/Images/ser3.png";
import Img4 from "../../Assests/Images/ser4.png";
import Img5 from "../../Assests/Images/ser5.png";
import "@splidejs/react-splide/css";
import { Splide, SplideSlide } from "@splidejs/react-splide";

const ServiceHero = () => {
  return (
    <div className="relative flex flex-col gap-8 justify-center items-center overflow-hidden pt-[2%] xsm:gap-4 md:gap-4 xsm:pt-[4%]">
      <div className="flex flex-col gap-3 justify-center items-center text-center font-robo  xsm:px-[4%]">
        {/* <p className="text-[30px] font-extrabold xsm:text-[18px] md:text-[25px]">FULL SERVICE</p> */}
        <p className="text-[50px] capitalize font-extrabold leading-[6rem] xsm:text-[18px] xsm:leading-none md:text-[40px] md:leading-[3rem] md:px-[3%]">
        Ignite Your Tech Adventure with HM Code Lab
        </p>
        <p className="text-[22px]   xsm:text-[10px] md:text-[16px]">
        We create bespoke digital solutions that exceed expectations. Ready to innovate? 
        </p>
      </div>
      <div className="flex flex-col justify-center items-center font-robo gap-3">
        <p className="text-[22px]   xsm:text-[8px] md:text-[14px]">
        Click 'Get in Touch' and let’s collaborate!
        </p>
        <Link to='/getintouch' className="text-[22px] text-[#000] font-black bg-gradient-to-r from-[#9EC1F5] via-[#B2BEFB] to-[#9EC1F5] px-5 py-2 capitalize rounded-2xl outline outline-[6px] outline-[#FFFFFF1A] xsm:text-[10px] xsm:rounded-lg md:text-[18px] md:rounded-xl">
          Get in Touch
        </Link>
      </div>

      <div className="pt-6 flex rotate-9 ">
          <div className="flex flex-wrap w-max gap-4">
            <div className=" flex flex-row gap-4 xsm:gap-2 animate-[scrollleft_16s_linear_infinite]">
              <img src={Img1} className="xsm:w-44 xsm:h-28" />
              <img src={Img2} className="xsm:w-44 xsm:h-28" />
              <img src={Img3} className="xsm:w-44 xsm:h-28" />
              <img src={Img4} className="xsm:w-44 xsm:h-28" />
              <img src={Img5} className="xsm:w-44 xsm:h-28" />
            </div>
            <div className="flex flex-row gap-4 xsm:gap-2 animate-[scrollleft_16s_linear_infinite]">
              <img src={Img1} className="xsm:w-44 xsm:h-28" />
              <img src={Img2} className="xsm:w-44 xsm:h-28" />
              <img src={Img3} className="xsm:w-44 xsm:h-28" />
              <img src={Img4} className="xsm:w-44 xsm:h-28" />
              <img src={Img5} className="xsm:w-44 xsm:h-28" />
            </div>
          </div>
      </div>
        <div className="h-[30vh] absolute bottom-0 w-full bg-gradient-to-t from-[#000] via-[#171612E3] to-[#17161200] xsm:h-[50px] xsm:-bottom-1 md:h-[70px]"></div>
    </div>
  );
};

export default ServiceHero;
