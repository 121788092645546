import React from 'react'
import { ReactComponent as Arrow } from "../../Assests/Icons/collabarrow.svg";
import Img1 from '../../Assests/Images/pro-1.png'
import Img2 from '../../Assests/Images/pro-2.png'
import Img3 from '../../Assests/Images/pro-Home.png'
import Img4 from '../../Assests/Images/pro-4.png'
import Img5 from "../../Assests/Images/oneim.png"

const FullProject = () => {
  return (
    <div className='font-int mt-[5%]'>
        <div className='flex flex-col justify-center gap-3 items-center px-[7%] pb-[3%] border-b border-[#72716B] xsm:mx-[4%] xsm:gap-1 md:px-[5%] md:pt-[5%]'>
                <p className='text-[#FFFFFB] capitalize font-bold text-[50px] xsm:text-[24px] md:text-[45px]'>In-Depth Look at Our Work</p>
                <p className='text-[#F9F6E0] text-[22px] xsm:text-[8px] md:text-[14px]'>An insider's view of our processes, solutions, and the measurable effects we deliver.</p>
        </div>
        <div className='grid grid-cols-4 border-b border-[#72716B] '>
            <div className=' px-6 pb-0 pt-10 flex flex-col justify-between border-r border-[#72716B] xsm:px-2 xsm:py-2 md:px-3 md:py-3'>
                <div>
                    <img src={Img1} alt="" className='h-[572px] w-full  object-cover object-top xsm:h-[250px] md:h-[350px]' />
                    <div className='flex flex-col text-white mt-6 xsm:mt-2 md:mt-4'>
                        {/* <p className='text-[15px] xsm:text-[8px] md:text-[12px]'>Jan 2024</p> */}
                        <p className='font-bold text-[28px] leading-none xsm:text-[10px] md:text-[20px]'>Grocerify</p>
                    </div>
                </div>
                <div className='mt-7 flex flex-col gap-1 xsm:mt-4 md:mt-4'>
                    {/* <button className="bg-[#9EC1F5] text-[15px] text-[#000] font-int font-bold rounded-md flex flex-row gap-2 justify-center items-center py-3 px-5 w-max cursor-pointer">
                        ALL PROJECTS <Arrow className="w-3 h-3" />
                    </button> */}
                    {/* <p className='font-black text-[#9EC1F5] text-[14px] xsm:text-[7px] md:text-[10px]'>/01</p> */}
                </div>
            </div>
            <div className=' px-6 pb-0 pt-10 flex flex-col justify-between border-r border-[#72716B] xsm:px-2 xsm:py-2 md:px-3 md:py-3'>
                <div>
                    <img src={Img5} alt="" className='h-[572px] w-full  object-cover object-top xsm:h-[250px] md:h-[350px]' />
                    <div className='flex flex-col text-white mt-6 xsm:mt-2 md:mt-4'>
                        {/* <p className='text-[15px] xsm:text-[8px] md:text-[12px]'>Feb 2024</p> */}
                        <p className='font-bold text-[28px] leading-none xsm:text-[10px] md:text-[20px]'>One Impression</p>
                    </div>
                </div>
                <div className='mt-7 flex flex-col gap-1 xsm:mt-4 md:mt-4'>
                    {/* <button className="bg-[#9EC1F5] text-[15px] text-[#000] font-int font-bold rounded-md flex flex-row gap-2 justify-center items-center py-3 px-5 w-max cursor-pointer">
                        ALL PROJECTS <Arrow className="w-3 h-3" />
                    </button> */}
                    {/* <p className='font-black text-[#9EC1F5] text-[14px]  xsm:text-[7px] md:text-[10px]'>/02</p> */}
                </div>
            </div>
            <div className=' px-6 pb-0 pt-10 flex flex-col justify-between  border-r border-[#72716B] xsm:px-2 xsm:py-2 md:px-3 md:py-3 '>
                <div>
                    <img src={Img2} alt="" className='h-[572px] object-cover object-top w-full xsm:h-[250px] md:h-[350px]' />
                    <div className='flex flex-col text-white mt-6 xsm:mt-2 md:mt-4'>
                        {/* <p className='text-[15px] xsm:text-[8px] md:text-[12px]'>Mar 2024</p> */}
                        <p className='font-bold text-[28px] leading-none xsm:text-[10px] md:text-[20px]'>HM Courses</p>
                    </div>
                </div>
                <div className='mt-7 flex flex-col gap-1 xsm:mt-4 md:mt-4'>
                    {/* <button className="bg-[#9EC1F5] text-[15px] text-[#000] font-int font-bold rounded-md flex flex-row gap-2 justify-center items-center py-3 px-5 w-max cursor-pointer">
                        ALL PROJECTS <Arrow className="w-3 h-3" />
                    </button> */}
                    {/* <p className='font-black text-[#9EC1F5] text-[14px] xsm:text-[7px] md:text-[10px]'>/03</p> */}
                </div>
            </div>
            <div className=' px-6 pb-0 pt-10 flex flex-col justify-between xsm:px-2 xsm:py-2 md:px-3 md:py-3'>
                <div>
                    <img src={Img3} alt="" className='h-[572px] w-full  object-cover object-top xsm:h-[250px] md:h-[350px]' />
                    <div className='flex flex-col text-white mt-6 xsm:mt-2 md:mt-4'>
                        {/* <p className='text-[15px] xsm:text-[8px] md:text-[12px]'>Apr 2024</p> */}
                        <p className='font-bold text-[28px] leading-none xsm:text-[10px] md:text-[20px]'>NFC Cards</p>
                    </div>
                </div>
                <div className='mt-7 flex flex-col gap-1 xsm:mt-4 md:mt-4'>
                    {/* <button className="bg-[#9EC1F5] text-[15px] text-[#000] font-int font-bold rounded-md flex flex-row gap-2 justify-center items-center py-3 px-5 w-max cursor-pointer">
                        ALL PROJECTS <Arrow className="w-3 h-3" />
                    </button> */}
                    {/* <p className='font-black text-[#9EC1F5] text-[14px] xsm:text-[7px] md:text-[10px]'>/04</p> */}
                </div>
            </div>
        </div>
    </div>
  )
}

export default FullProject