import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import Logo from "../../Assests/Images/hm-logo.png";
import India from "../../Assests/Icons/India.png";
import UAE from "../../Assests/Icons/UAE.png";
import { ReactComponent as Whatsapp } from "../../Assests/Icons/whatsapp.svg";

const Header = () => {
  return (
    <div className="flex flex-row justify-between gap-6 bg-[#000000] h-[20vh] px-[5%] xsm:h-16 md:h-28 py-[1%]">
      <Link to="/">
        <img
          src={Logo}
          className="w-32 h-28 xsm:w-16 xsm:h-12 xsm:mt-2 md:h-24 md:w-28"
        />
      </Link>
      <div className="flex flex-col w-full items-end justify-end h-28  xsm:h-14 xsm:pt-0 md:h-24">
        <div className="flex flex-row font-int text-white items-center text-[18px] gap-4 md:text-[12px] xsm:text-[5px] xsm:gap-2">
          {/* <Link
            to="mailto:info@hmcodelab.com"
            className="pb-3 xsm:pb-2"
            target="_blank"
          >
            info@hmcodelab.com
          </Link> */}
          <div className="h-full pl-4 pb-3 flex flex-row text-[18px] gap-3 items-center justify-center md:text-[14px] md:pl-3 md:gap-2 xsm:pb-2 xsm:text-[6px] xsm:pl-2 xsm:gap-2">
            {" "}
            <img src={UAE} className="md:w-6 xsm:w-3" />{" "}
            <span>+971 52 208 5773</span>{" "}
          </div>
          <div className="border-l-[1px] border-[#72716B] h-full pl-4 pb-3 flex flex-row text-[18px] gap-3 items-center justify-center md:text-[14px] md:pl-3 md:gap-2 xsm:pb-2 xsm:text-[6px] xsm:pl-2 xsm:gap-2">
            {" "}
            <img src={India} className="md:w-6 xsm:w-3" />{" "}
            <span>+91 90561 22600</span>{" "}
          </div>
        </div>
        <div className="flex flex-row border-y-[1px] border-[#72716B]  text-[#f9f6e0] text-[24px] font-int w-full justify-end xsm:text-[8px] md:text-[16px]">
          <div className="flex flex-row">
            <div className="paste-button border-x-[1px] border-[#72716B]">
              <Link
                to={"/projects"}
                className="mx-8 my-3 py-1 px-2 flex flex-row gap-2 justify-center items-center text-center nav-button text-[22px] xsm:text-[8px] xsm:mx-1 xsm:my-1 xsm:px-1 md:mx-3 md:my-2 md:text-[16px]"
              >
                <span className="span-nav">Projects</span>
                {/* <span className="" style={{ fontSize: "0.5em" }}>
                  {" "}
                  &nbsp; ▼{" "}
                </span>{" "} */}
              </Link>
              {/* <div class="dropdown-content">
                <Link id="top" href="#" className="content">
                  Keep source formatting
                </Link>
                <Link id="middle" href="#" className="content">
                  Merge formatting
                </Link>
                <Link id="bottom" href="#" className="content">
                  Keep text only

                </Link>
              </div> */}
            </div>
            <Link
              to="/services"
              className="mx-8 my-3 py-1 px-2 justify-center nav-button text-[22px] xsm:text-[8px] xsm:mx-1 xsm:my-1 xsm:px-1 md:mx-3 md:my-2 md:text-[16px]"
            >
              <span className="span-nav">Our Service</span>
            </Link>
            <div className="flex border-x-[1px] border-[#72716B]">
              <Link
                to="/getintouch"
                className="mx-8 my-3 py-1 px-2 justify-center items-center nav-button text-[22px] xsm:text-[8px] xsm:mx-1 xsm:my-1 xsm:px-1 md:mx-3 md:my-2 md:text-[16px]"
              >
                <span className="span-nav">Get In Touch</span>
              </Link>
            </div>
            <Link to={"https://wa.me/message/LFTH7BZFV6XUN1"} target="_blank">
              <div className="mx-8 my-3 py-1 px-2 flex flex-row gap-2 justify-center items-center nav-button text-[22px] xsm:text-[8px] xsm:mx-1 xsm:my-1 xsm:px-1 md:mx-3 md:my-2 md:text-[16px]">
                {" "}
                <span className="flex flex-row gap-2 justify-center items-center xsm:gap-1 span-nav">
                  {" "}
                  <Whatsapp className="w-7 h-7 xsm:w-3 xsm:h-3 md:h-5 md:w-5" />{" "}
                  WhatsApp
                </span>{" "}
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
