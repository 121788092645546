import { useEffect, useRef, useState } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "./Herosection.css";
import "../Animation/Glow.css";
import Img1 from "../../Assests/Images/hero-1.png";
import Img2 from "../../Assests/Images/hero-2.png";
import Img3 from "../../Assests/Images/hero-3.png";
import Img4 from "../../Assests/Images/hero-4.png";
import LaptopFrame from "../../Assests/Images/LaptopFrame.png"
import IpadFrame from "../../Assests/Images/iPadMiniFrame.png"
import PhoneFrame from "../../Assests/Images/iPhoneFrame.png"
import PhoneScroll from "../../Assests/Images/PhoneScrollImg.png"
import TabScroll from "../../Assests/Images/TabletScrollImg.png"
import LaptopScroll from "../../Assests/Images/landingdesktop.png"
import ImgBG from "../../Assests/Images/Herosectionimgbg.png"
import '@splidejs/react-splide/css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import Scroll1 from "../../Assests/Images/LaptopScrollImg.png"
import Scroll2 from "../../Assests/Images/-img.png"
import Scroll3 from "../../Assests/Images/Landing Home 2.png"

const Herosection = () => {
  
  const Images = [
    {
      image: Scroll1,
    },
    {
      image: Scroll2,
    },
    {
      image: Scroll3,
    },
  ];
  const [currImageIndex, setCurrImageIndex] = useState(0);
  
  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);
 
    
  useEffect(() => {
    const interval = setInterval(() => {
      // Calculate the next index
      const nextIndex = (currImageIndex + 1) % Images.length;
      setCurrImageIndex(nextIndex);
    }, 14000);

    return () => {
      clearInterval(interval); // Clean up interval on component unmount
    };
  }, [currImageIndex, Images.length]);

  const currImage = Images[currImageIndex].image;


  return (
    <main id="main">
      <span id="round" ref={roundRef}></span>
    <div className={`bg-[#000] text-white ${window.innerWidth >= 2000 ? 'overflow-hidden' : ''}`}>
      <GridLines
        strokeWidth={4}
        lineColor="#1B1E2D80"
        cellWidth={50}
        cellHeight={42}
      >
        <div className=" flex flex-col pb-[6%] items-center gap-10 px-[5%] py-[3%] gridglow">
          <div className="flex flex-col gap-4 justify-center items-center font-int xsm:gap-3">
            <p className="capitalize tracking-wider text-[50px] font-bold xsm:text-[12px] text-center md:text-[30px]">Ignite Your Tech Adventure with HMCodelab!</p>
            <p className="text-[22px] capitalize text-center px-[5%] xsm:text-[9px] xsm:px-[2%] md:text-[14px]">Explore exciting tech possibilities with HMCodeLab's handpicked resources that will inspire and support your digital learning journey.</p>
          </div>
          <div className="flex relative">
            <div className="absolute overflow-hidden fadeInLeft-LgTab bottom-[5%] rounded-2xl xsm:rounded-lg">
              <img className="relative w-full h-full z-40 xsm:h-20 md:h-40" src={PhoneFrame} alt=""/>
              <div className="absolute top-[2%] left-[5%] z-30 rounded-2xl h-[98%] overflow-hidden xsm:rounded-md">
                <img className="rounded-xl animateScrollphone xsm:rounded-md" src={currImage} alt=""/>
              </div>
            </div>
            <div className="z-[1] relative overflow-hidden fadeInBottom-Laptop ">
              <img className="z-40 relative xsm:h-40 md:h-60" src={LaptopFrame} alt=""/>
              <div className="absolute top-[1%] left-[10%] w-[80%] h-[98%] overflow-hidden z-0">
                <img className="rounded-md animateScrollLatop w-full" src={currImage} alt=""/>
              </div>
            </div>
            <div className="absolute overflow-hidden bottom-[5%] right-0 rounded-2xl xsm:rounded-lg fadeInRight-smTab">
              <img className="relative w-full h-full z-40 xsm:h-24 md:h-44" src={IpadFrame} alt=""/>
              <div className="absolute top-[2%] left-[3%] z-30 rounded-2xl h-[98%] overflow-hidden xsm:rounded-lg">
                <img className="rounded-3xl animateScrollTab xsm:rounded-lg" src={currImage} alt=""/>
              </div>
            </div>
            <div className="w-[120%] h-[120%] absolute -top-[45%] -left-[10%] z-0 xsm:-top-10">
              {/* <img src={ImgBG} alt="" className="xsm:h-[130%] xsm:w-[100%]"/> */}
            </div>
          </div>
        </div>
      </GridLines>
    </div>
    </main>
  );
};

export default Herosection;
