import React from 'react';
import Phone from "../../Assests/Images/service-phone.png";
import Icon1 from "../../Assests/Icons/website.png";
import Icon2 from "../../Assests/Icons/app.png";
import Icon3 from "../../Assests/Icons/seo.png";
import Icon4 from "../../Assests/Icons/digital.png";
import StackedCards from '../Animation/CardsSwap';

const AllServices = () => {
  return (
    <div className='flex flex-col gap-20 pt-[5%] pb-[6%] justify-center items-center font-int xsm:gap-8 md:gap-8 xsm:pt-[8%]'>
      <div className='flex flex-col gap-2 justify-center items-center text-center xsm:px-[10%] md:px-[8%]'>
        <p className='text-[50px] font-bold xsm:text-[18px] md:text-[46px] capitalize'>Services at <span className='text-[#9EC1F5] xsm:text-[18px] md:text-[46px]'>HMCodelab</span></p>
        <p className='text-[22px] xsm:text-[10px] text-center md:text-[18px]'>Explore bespoke tech solutions at HMCodelab: where innovation meets customization! </p>
      </div>

      <div className='flex flex-row justify-between px-[6%] xsm:px-[3%] md:px-[3%]'>
        <div className='relative flex flex-col justify-end xsm:justify-center xsm:w-[45%] md:justify-center md:w-[45%]'>
          <img src={Phone} className='h-max w-full xsm:w-[80%] md:w-[80%] ' />
          <div className='absolute translate-x-40 bottom-8 xsm:translate-x-14 xsm:bottom-14 md:translate-x-32 md:bottom-12'> <StackedCards /> </div>
        </div>
        <div className='grid grid-cols-2 gap-20 gap-x-32 w-[55%] xsm:gap-8 xsm:gap-x-5 md:gap-6 md:gap-x-4'>
          <div className='flex flex-col gap-3 font-int text-[16px] xsm:gap-[6px] md:gap-2'>
            <div className='w-[70px] h-[70px] rounded-full bg-[#9EC1F5] flex justify-center items-center xsm:w-[30px] xsm:h-[30px] md:w-[50px] md:h-[50px]'><img src={Icon1} className='xsm:w-[60%] md:w-[60%]' /></div>
            <p className='text-[24px] font-bold leading-7 xsm:text-[12px] xsm:leading-none md:text-[18px] md:leading-none'>Impactful <br /> Web Solutions</p>
            <p className='text-justify xsm:text-[8px] xsm:text-balance md:text-[14px] md:text-balance'>We create bespoke, responsive, SEO-friendly websites tailored to your brand and audience, ensuring an outstanding user experience and tangible business growth. Stand out with our custom solutions. </p>
          </div>
          <div className='flex flex-col gap-3 font-int text-[16px] xsm:gap-[6px] md:gap-2'>
            <div className='w-[70px] h-[70px] rounded-full bg-[#9EC1F5] flex justify-center items-center xsm:w-[30px] xsm:h-[30px] md:w-[50px] md:h-[50px]'><img src={Icon2} className='xsm:w-[60%] md:w-[60%]' /></div>
            <p className='text-[24px] font-bold leading-7 xsm:text-[12px] xsm:leading-none md:text-[18px] md:leading-none'>Customized <br /> Software Solutions</p>
            <p className='text-justify xsm:text-[8px] xsm:text-balance md:text-[14px] md:text-balance'>Our customized software solutions enhance productivity, optimize operations, and support scalability using cutting-edge tech and agile methods for seamless integration and business success. </p>
          </div>
          <div className='flex flex-col gap-3 font-int text-[16px] xsm:gap-[6px] md:gap-2'>
            <div className='w-[70px] h-[70px] rounded-full bg-[#9EC1F5] flex justify-center items-center xsm:w-[30px] xsm:h-[30px] md:w-[50px] md:h-[50px]'><img src={Icon3} className='xsm:w-[60%] md:w-[60%]' /></div>
            <p className='text-[24px] font-bold leading-7 xsm:text-[12px] xsm:leading-none md:text-[18px] md:leading-none'>Universal <br /> Mobile Engagement </p>
            <p className='text-justify xsm:text-[8px] xsm:text-balance md:text-[14px] md:text-balance'>We excel in crafting captivating, user-friendly iOS and Android apps that enhance engagement, retention, and brand loyalty with intuitive design and seamless performance.</p>
          </div>
          <div className='flex flex-col gap-3 font-int text-[16px] xsm:gap-[6px] md:gap-2'>
            <div className='w-[70px] h-[70px] rounded-full bg-[#9EC1F5] flex justify-center items-center xsm:w-[30px] xsm:h-[30px] md:w-[50px] md:h-[50px]'><img src={Icon4} className='xsm:w-[60%] md:w-[60%]' /></div>
            <p className='text-[24px] font-bold leading-7 xsm:text-[12px] xsm:leading-none md:text-[18px] md:leading-none'>GrowthMax <br /> Online Visibility</p>
            <p className='text-justify xsm:text-[8px] xsm:text-balance md:text-[14px] md:text-balance'>Our marketing strategies boost visibility and growth through SEO, SEM, and content, analyzing trends and crafting campaigns that attract leads and drive conversions. </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AllServices