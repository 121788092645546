import React from 'react'
import "./Projects.css";

const ProjectCard = ({Img1,Img2,Img3,title,type}) => {
  return (
    <div>
        <div className='flex items-center gap-5 xsm:gap-2'>
            <div className=' w-[70%] flex flex-col gap-5 xsm:gap-2'>
                <div className='w-full h-[310px] rounded-2xl overflow-hidden xsm:h-[130px] xsm:rounded-xl md:h-[210px] md:rounded-2xl'>
                    <img className='w-full animateScrolling' src={Img1} alt="" />
                </div>
                <div className='w-full h-[310px] rounded-2xl overflow-hidden xsm:h-[130px] xsm:rounded-xl md:h-[210px]'>
                    <img className='object-cover' src={Img1}  alt="" />
                </div>
            </div>
            <div className='w-[30%] h-[600px] object-cover object-top overflow-hidden md:h-[400px] xsm:h-[250px]'>
                <img className='w-full h-full' src={Img1}  alt="" />
            </div>
        </div>
        <div className='flex justify-between items-start text-white mt-10 xsm:mt-5'>
            <p className='w-[60%] font-medium text-[20px] xsm:text-[11px] md:text-[20px]'>{title}</p>
            <p className='border-[3px] border-white font-light rounded-full px-5 py-2 xsm:text-[8px] xsm:px-2 xsm:py-[2px] xsm:border-2 md:py-1 md:px-3 md:text-[14px]'>{type}</p>
        </div>
    </div>
  )
}

export default ProjectCard