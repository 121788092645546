import React, { useState } from 'react'
import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "../Animation/Glow.css";
import ProjectsHeroSection from './ProjectsHeroSection'
import ProjectsSection from './ProjectsSection';
import FullProject from './FullProject';
import { Helmet } from 'react-helmet';

const ProjectsMain = () => {

  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  const [category, setCategory] = useState("FEATURED");

  return (
    <main id="main">
    <Helmet>
      <title>HM CodeLab - Projects</title>
      <meta name="description" content="Discover HM CodeLab's innovative projects. Our portfolio highlights successful digital solutions for nonprofits, startups, corporates, and SMBs. Visit HM CodeLab Projects to see how we transform visions into reality with cutting-edge technology and expertise." data-react-helmet="true"/>
      <meta property="og:title" content="HM CodeLab - Projects" data-react-helmet="true"/>
      <meta property="og:type" content="website" data-react-helmet="true"/>
      <meta property="og:image" content="/og_image_projects.png" data-react-helmet="true"/>
      <meta property="og:image:width" content="526" data-react-helmet="true"/>
      <meta property="og:image:height" content="275" data-react-helmet="true"/>
      <meta property="og:image:type" content="image/png" data-react-helmet="true"/>
      <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
      <meta property="og:description" content="Discover HM CodeLab's innovative projects. Our portfolio highlights successful digital solutions for nonprofits, startups, corporates, and SMBs. Visit HM CodeLab Projects to see how we transform visions into reality with cutting-edge technology and expertise." data-react-helmet="true"/>
      <meta property="og:site_name" content="HM CodeLab - Projects" data-react-helmet="true"/>
    </Helmet>
    <span id="round" ref={roundRef}></span>
    <div className='bg-[#000]'>
        <GridLines
            strokeWidth={4}
            lineColor="#1B1E2D80"
            cellWidth={50}
            cellHeight={42}
        >
          <div className="gridglow">
            <ProjectsHeroSection setCategory={setCategory}/>
            <ProjectsSection category={category}/>
            <FullProject/>
            </div>
        </GridLines>
    </div>
    </main>
  )
}

export default ProjectsMain