import React, { useState, useEffect, createContext, useLayoutEffect } from 'react';
import './App.css';
import Router from './Routing/Route';
import { Toaster } from 'react-hot-toast';
import CallButton from './Components/CallButton/CallButton';
import HomePopUp from './Components/HomePopUp/HomePopUp';

function App() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  const closePopup = () => {
    console.log("Popup closed");
    setShowPopup(false);
  };

  return (
    <div className="2xl:px-[17%]">
      <Router />
      <Toaster/>
      <CallButton />
      {/* {showPopup && <HomePopUp onClose={closePopup} />} */}
    </div>
  );
}

export default App;
