import React from "react";
import GridLines from "react-gridlines";
import Icon1 from "../../Assests/Icons/swift.png";
import Icon2 from "../../Assests/Icons/objC.png";
import Icon3 from "../../Assests/Icons/Kotlin.png";
import Icon4 from "../../Assests/Icons/ionic.png";
import Icon5 from "../../Assests/Icons/React-Native.png";
import Icon6 from "../../Assests/Icons/Flutter.png";
import Icon7 from "../../Assests/Icons/Vue.png";
import Icon8 from "../../Assests/Icons/React-js.png";
import Icon9 from "../../Assests/Icons/angular.png";
import Icon10 from "../../Assests/Icons/Python.png";
import Icon11 from "../../Assests/Icons/NodeJS.png";
import Icon12 from "../../Assests/Icons/TypeScript.png";

const Technologies = () => {
  return (
    <div className="flex flex-col bg-[#000] text-white font-int">
      <GridLines
        strokeWidth={4}
        lineColor="#1B1E2D80"
        cellWidth={50}
        cellHeight={42}
      >
        <div className="flex flex-col items-center gap-2 px-[6%] pt-[6%] xsm:pt-[12%]">
          <p className="text-[40px] font-bold xsm:text-[14px] md:text-[30px] uppercase text-center">
          Mastery Across Technologies
          </p>
          <p className="text-[20px] xsm:text-[9px] md:text-[12px] text-center w-[80%]">
          Explore our wide-ranging tech expertise from mobile to web development. Elevate your project with our innovative, tailored solutions.
          </p>
        </div>

        <div className="mx-[6%] border-y-[1px] border-[#72716B] grid grid-cols-2 divide-x-[1px] divide-[#72716B] my-6">
          <div className="flex flex-col justify-center items-center xsm:justify-start">
            <p className="text-[36px] font-semibold text-center py-6 leading-10 xsm:text-[12px] xsm:leading-none xsm:py-4 md:text-[26px] md:leading-8">
              Mobile <br /> Development
            </p>
            <div className="flex flex-row justify-center items-center py-[6%] border-t-[1px] border-[#72716B] w-full divide-x-[1px] divide-[#72716B]">
              <div className="flex flex-col gap-10 text-[24px] font-medium font-Degular Demo w-[50%] justify-center items-center xsm:text-[10px] xsm:gap-6 md:text-[14px]">
                <div className="flex flex-col justify-center items-center gap-1">
                  <img src={Icon1} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                  Swift
                </div>
                <div className="flex flex-row justify-around w-full">
                  <div className="flex flex-col justify-center items-center gap-1">
                    <img src={Icon2} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    objC
                  </div>
                  <div className="flex flex-col justify-center items-center gap-1">
                    <img src={Icon3} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    Kotlin
                  </div>
                </div>
                <div className="text-[#9EC1F5] text-[28px] font-int font-bold xsm:text-[12px] md:text-[20px]">
                  Native
                </div>
              </div>
              <div className="flex flex-col gap-10 text-[24px] font-medium font-Degular Demo w-[50%] justify-center items-center xsm:text-[10px] xsm:gap-6 md:text-[14px]">
                <div className="flex flex-col justify-center items-center pt-4 gap-1">
                  <img src={Icon4} className="w-36 h-12 xsm:w-10 xsm:h-2 md:h-6 md:w-20" />
                  Ionic
                </div>
                <div className="flex flex-row justify-around w-full">
                  <div className="flex flex-col justify-center items-center text-center gap-1">
                    <img src={Icon5} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    React Native
                  </div>
                  <div className="flex flex-col justify-center items-center gap-1">
                    <img src={Icon6} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    Flutter
                  </div>
                </div>
                <div className="text-[#9EC1F5] text-[28px] font-int font-bold xsm:text-[12px] md:text-[20px]">
                  Hybrid
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col justify-center items-center xsm:justify-start">
            <p className="text-[36px] font-semibold text-center py-6 leading-10 xsm:text-[12px] xsm:leading-none xsm:py-4 md:text-[26px] md:leading-8">
              Web <br /> Development
            </p>
            <div className="flex flex-row justify-center items-center py-[6%] border-t-[1px] border-[#72716B] w-full divide-x-[1px] divide-[#72716B]">
              <div className="flex flex-col gap-10 text-[24px] font-medium font-Degular Demo w-[50%] justify-center items-center xsm:text-[10px] xsm:gap-6 md:text-[14px]">
                <div className="flex flex-col justify-center items-center gap-1">
                  <img src={Icon7} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                  Vue JS
                </div>
                <div className="flex flex-row justify-around w-full">
                  <div className="flex flex-col justify-center items-center gap-1">
                    <img src={Icon8} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    React JS
                  </div>
                  <div className="flex flex-col justify-center items-center gap-1">
                    <img src={Icon9} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    Angular
                  </div>
                </div>
                <div className="text-[#9EC1F5] text-[28px] font-int font-bold xsm:text-[12px] md:text-[20px]">
                Frontend
                </div>
              </div>
              <div className="flex flex-col gap-10 text-[24px] font-medium font-Degular Demo w-[50%] justify-center items-center xsm:text-[10px] xsm:gap-6 md:text-[14px]">
                <div className="flex flex-col justify-center items-center gap-1">
                  <img src={Icon10} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                  Python
                </div>
                <div className="flex flex-row justify-around w-full">
                  <div className="flex flex-col justify-center items-center text-center gap-1">
                    <img src={Icon11} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    Node JS
                  </div>
                  <div className="flex flex-col justify-center items-center text-center gap-1">
                    <img src={Icon12} className="w-16 h-16 xsm:w-6 xsm:h-6 md:h-10 md:w-10" />
                    Type Script
                  </div>
                </div>
                <div className="text-[#9EC1F5] text-[28px] font-int font-bold xsm:text-[12px] md:text-[20px]">
                Backend
                </div>
              </div>
            </div>
          </div>
        </div>
      </GridLines>
    </div>
  );
};

export default Technologies;
