import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assests/Images/hm-logo2.png";
import { ReactComponent as Arrow } from "../../Assests/Icons/footer-arrow.svg";
import India from "../../Assests/Icons/India.png"
import USA from "../../Assests/Icons/USA.png"
import UAE from "../../Assests/Icons/UAE.png"

const Footer = () => {
  return (
    <div className="flex flex-col gap-16 bg-[#000000] px-[5%] pt-[6%] pb-[3%] xsm:gap-8">
      {/* <div className="w-[70%] flex justify-end xsm:w-[85%]">
        <Link to='/getintouch' className="bg-[#9EC1F5] text-[16px] text-[#592020] font-int rounded-md flex flex-row gap-2 justify-center items-center py-4 px-6 xsm:gap-1 xsm:text-[10px] xsm:py-2 xsm:px-3">
          Get in Touch <Arrow className="w-3 h-3 xsm:w-2 xsm:h-2" />{" "}
        </Link>
      </div> */}

      <div className="flex flex-col">
        <div className="flex flex-row items-end">
          <div className="w-[30%] py-2">
            <img
              src={Logo}
              className="w-28 h-28 xsm:w-16 xsm:h-16 md:h-24 md:w-24"
            />
          </div>
          <div className="w-[28%] xsm:w-[30%]">
            <p className="text-[24px] text-[#FFFFFF] font-int font-bold py-2 xsm:text-[10px] md:text-[18px] md:px-0">
              Quick Link’s
            </p>
          </div>
          <div className="w-[30%]">
            <p className="text-[24px] text-[#FFFFFF] font-int font-bold py-2 xsm:text-[10px] md:text-[18px] md:">
              Contact Us
            </p>
          </div>
        </div>
        <div className="border-t-[1px] border-[#72716B] flex flex-row">
          <div className="w-[30%]">
            <p className="text-[20px] text-[#FFFFFF] font-int fonr-medium w-[70%] py-2 xsm:text-[8px] md:text-[14px]">
              Embark on Your Tech Adventure-Ignite Innovation, Transform
              Possibilities, and Redefine Boundaries.
            </p>
          </div>
          <div className="flex flex-row justify-between w-[70%] md:gap-12 xsm:gap-10">
            <div className="w-[30%] text-[#FFFFFF] text-[20px] font-int font-medium flex flex-col xsm:text-[8px] md:text-[14px]">
              <Link to={"/"} className="py-2 xsm:py-1">
                Home
              </Link>
              <Link
                to={"/projects"}
                className="border-t-[1px] border-[#72716B] py-2 xsm:py-1"
              >
                Projects
              </Link>
              <Link
                to={"/services"}
                className="border-t-[1px] border-[#72716B] py-2 xsm:py-1"
              >
                Services
              </Link>
              <Link
                to={"/getintouch"}
                className="border-t-[1px] border-[#72716B] py-2 xsm:py-1"
              >
                Contact
              </Link>
            </div>
            <div className="w-[60%] text-[#FFFFFF] text-[20px] font-int font-medium flex flex-col xsm:text-[8px] md:text-[14px]">
              <Link to={"https://wa.me/qr/S3LVDB3Y3SB3H1"} className="py-2 xsm:py-1">
              +91 90561 22600 , +971 52 208 5773
              </Link>
              <Link
                to="mailto:info@hmcodelab.com"
                target="_blank"
                className="border-y-[1px] border-[#72716B] py-2 xsm:py-1"
              >
                info@hmcodelab.com
              </Link>
              {/* <div className="space-y-2 "></div> */}
              <div className="grid grid-cols-3 grid-flow-row-dense justify-center items-start text-center mt-8  text-[20px] xsm:text-[8px] xsm:mt-4 md:mt-6 md:text-[14px]">
                <div className="space-y-2 px-2 xsm:px-1">
                  <p className="flex justify-center gap-2 xsm:gap-1"> <span> <img src={India} className="md:w-5 md:h-5 xsm:w-3 xsm:h-3" /> </span>India</p>
                  <p>E-299, Industrial Area, Sector 75, SAS Nagar, PB</p>
                </div>
                <div className="space-y-2 px-2 border-x-[1px] border-[#72716B] xsm:px-1">
                  <p className="flex justify-center gap-2 xsm:gap-1"> <span> <img src={USA} className="md:w-5 md:h-5 xsm:w-3 xsm:h-3" /> </span>USA</p>
                  <p>2150 s Mcclelland st Salt lake city Utah 84106</p>
                </div>
                <div className="space-y-2 px-2 xsm:px-1">
                  <p className="flex justify-center gap-2 xsm:gap-1"> <span> <img src={UAE} className="md:w-5 md:h-5 xsm:w-3 xsm:h-3" /> </span>UAE</p>
                  <p>306, Daytona House, Motor City, Dubai, UAE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center">
        <p className="text-[#FFFFFF] text-[24px] font-int font-medium xsm:text-[12px] md:text-[16px]">
          © HM CODELAB 2024
        </p>
      </div>
    </div>
  );
};

export default Footer;
