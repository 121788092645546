import React from "react";
import Herosection from "../Herosection";
import Services from "../Services";
import CollabSection from "../CollabSection/CollabSection";
import Whychooseus from "../WhyChoose/Whychoose";
import CustomerSection from "../CustomerSection/CustomerSection";
import Technologies from "../Technologies";
import AllProjects from "./AllProjects";
import {Helmet} from "react-helmet";
import { useEffect, useRef } from "react";
import gsap from "gsap";
import "../Animation/Glow.css";

const Home = () => {

  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  return (
    <main id="main">
      <Helmet>
        <title>HM CodeLab</title>
        <meta name="description" content="Welcome to HM CodeLab, where we ignite innovation and transform possibilities into realities. Our mission is to redefine boundaries in the tech world through cutting-edge solutions and unparalleled expertise. Explore our diverse range of services and embark on a tech adventure like no other." data-react-helmet="true"/>
        <meta property="og:title" content="HM CodeLab" data-react-helmet="true"/>
        <meta property="og:type" content="website" data-react-helmet="true"/>
        <meta property="og:image" content="/og_image.png" data-react-helmet="true"/>
        <meta property="og:image:width" content="526" data-react-helmet="true"/>
        <meta property="og:image:height" content="275" data-react-helmet="true"/>
        <meta property="og:image:type" content="image/png" data-react-helmet="true"/>
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
        <meta property="og:description" content="Welcome to HM CodeLab, where we ignite innovation and transform possibilities into realities. Our mission is to redefine boundaries in the tech world through cutting-edge solutions and unparalleled expertise. Explore our diverse range of services and embark on a tech adventure like no other." data-react-helmet="true"/>
        <meta property="og:site_name" content="HM CodeLab" data-react-helmet="true"/>
      </Helmet>
      <span id="round" ref={roundRef}></span>

      <div className="bg-black text-white gridglow">
        <Herosection />
        <Services />
        <CustomerSection />
        <CollabSection />
        <AllProjects />
        <Technologies />
        <Whychooseus />
      </div>
      </main>
  );
};

export default Home;
