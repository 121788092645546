import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "../Animation/Glow.css";
import Arrow from "../../Assests/Icons/collabarrow.svg";
import Img1 from "../../Assests/Images/Landing Home 2.png";
import Img2 from "../../Assests/Images/HOME2.png";
import Img3 from "../../Assests/Images/Main Page.png";
import Img4 from "../../Assests/Images/-img.png";
import Probg from "../../Assests/Images/pro-bg.png";
import MobileBg from "../../Assests/Images/mobile-bg1.png"
import "./AllProjects.css"
import ReactPlayer from "react-player";
import Video1 from "../../Assests/Video/HomeProjectvideo1.mp4"
import Video2 from "../../Assests/Video/HomeProjectvideo2.mp4"
import Video3 from "../../Assests/Video/HomeProjectvideo6.mp4"
import Video4 from "../../Assests/Video/HomeProjectvideo7.mp4"

const AllProjects = () => {

  const [selectedCategory, setSelectedCategory] = useState("Website Design"); // State to hold the selected category

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const categories = {
    "Website Design": {
      projects: [
        { image: Img1, title: "Trustco." },
        { image: Img3, title: "NEWSLETTER" },
        { image: Img2, title: "BIO CWT" },
        { image: Img4, title: "Perfect Recipe" },
      ],
    },
    "Mobile/Web App Design": {
      projects: [
        { image: Img3, title: "Trustco." },
        { image: Img4, title: "NEWSLETTER" },
        { image: Img1, title: "BIO CWT" },
        { image: Img2, title: "Perfect Recipe" },
      ],
    },
    // Branding: {
    //   projects: [
    //     { image: Img1, title: "Mobile/Web App Design" },
    //     { image: Img4, title: "Citizen's Advice Sheffield" },
    //     { image: Img3, title: "Mobile/Web App Design" },
    //     { image: Img2, title: "Citizen's Advice Sheffield" },
    //   ],
    // },
  };


  const roundRef = useRef();

  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });


  }, []);

  return (
    <main id="main">
      <GridLines strokeWidth={4} lineColor="#1B1E2D80" cellWidth={50} cellHeight={42}>
        <div className="pt-[6%]  px-[8%] flex divide-x-[1px] divide-[#72716B] w-full gridglow">
          {/* left */}
          <div className=" px-[2%] py-[3%] border-y-[1px] border-[#72716B] w-[30%] stickyCard">
            {/* fixed card */}
            <div
              className="flex flex-col gap-12 px-4 py-8 rounded-xl stickyCard xsm:py-5 xsm:px-2 xsm:rounded-md xsm:gap-6 md:gap-8 md:px-3 md:py-6"
              style={{
                backgroundImage: `url(${Probg})`,
                backgroundPosition: "center",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                left: "3%",
                zIndex: 1,
              }}
            >
              <div className="flex flex-col gap-4 font-int text-[18px] xsm:text-[8px] xsm:gap-2 md:text-[14px] md:gap-3">
              {Object.keys(categories).map((category) => (
                  <p
                    key={category}
                    className={`px-2 cursor-pointer xsm:leading-none ${
                      selectedCategory === category ? "text-[#9EC1F5] border-l-4 border-[#9EC1F5] font-bold pl-4 xsm:pl-2 xsm:border-l-2 xsm:leading-none md:border-l-3 md:pl-3" : ""
                    }`}
                    onClick={() => handleCategoryClick(category)}
                  >
                    {category}
                  </p>
                ))}
              </div>
              <Link
                to="/projects"
                className="flex flex-row gap-2 bg-[#9EC1F5] text-black text-[16px] font-int font-bold justify-center items-center px-4 py-3 rounded-md xsm:text-[6px] xsm:p-1 xsm:gap-1 xsm:rounded-sm md:text-[10px] md:p-1 md:gap-1"
              >
                VIEW ALL PROJECTS
                <span className="flex items-center">
                  <img src={Arrow} className="w-4 h-4 xsm:h-2 xsm:w-2 md:w-2 md:h-2" alt="Arrow Icon" />
                </span>
              </Link>
            </div>
          </div>

          {/* right */}
          {selectedCategory === "Website Design" &&
            <div className="grid grid-cols-2 font-int font-extrabold w-[70%] border-y-[1px] border-[#72716B] divide-y-[1px] divide-[#72716B]">
              {categories[selectedCategory]?.projects.map((project, index) => (
                <div key={index} className={`p-6 flex flex-col gap-8 uppercase font-int font-extrabold text-[20px] xsm:p-2 xsm:text-[8px] md:p-4 md:text-[14px] xsm:gap-2  ${index%2===0?'border-r-[1px] border-[#72716B] ':'justify-between '}`}>
                  <div className="flex  flex-row gap-5 items-center xsm:gap-2 md:gap-4">
                    <div className="flex flex-col gap-5 w-[70%] xsm:gap-1 md:gap-4">
                      <div className="w-full h-[280px] overflow-hidden object-cover rounded-2xl xsm:h-[80px] xsm:rounded-md md:h-[160px]">
                        <img className="w-full animateScrollCard" src={project.image} alt={project.title} />
                      </div>
                      <img className="w-full h-[280px] rounded-2xl object-cover object-top xsm:h-[80px] xsm:rounded-md md:h-[160px]" src={project.image} alt={project.title} />
                    </div>
                    <img src={project.image} className="my-5 w-[30%] h-[500px] xsm:h-[140px] md:h-[300px]" alt={project.title} />
                  </div>
                  {project.title}
                </div>
              ))}
            </div>
          }
          {selectedCategory === "Mobile/Web App Design" &&
            <div className="grid grid-cols-2 font-int font-extrabold w-[70%] border-y-[1px] border-[#72716B] divide-y-[1px] divide-[#72716B]">
              <div className="flex flex-col items-center gap-6 p-6 pt-8 border-r md:gap-4 md:p-4 xsm:gap-3 xsm:p-2">
                <div className="overflow-hidden rounded-2xl w-[350px] h-[260px] md:w-[200px] md:h-[160px] xsm:w-[110px] xsm:h-[90px] bg-[#E7E9EB] xsm:rounded-lg">
                  <ReactPlayer url={Video1} width={'100%'} height={'100%'} playing={true}  loop muted={true}/>
                </div>
                <p className="font-int font-extrabold text-[20px] md:text-[14px] xsm:text-[8px]">Food Delivery App</p>
              </div>
              <div className="flex flex-col items-center gap-6 p-6 pt-8 md:gap-4 md:p-4 xsm:gap-3 xsm:p-2">
                <div className="overflow-hidden rounded-2xl w-[350px] h-[260px] md:w-[200px] md:h-[160px] xsm:w-[110px] xsm:h-[90px] bg-[#37393B] xsm:rounded-lg">
                  <ReactPlayer url={Video2} width={'100%'} height={'100%'} playing={true}  loop muted={true}/>
                </div>
                <p className="font-int font-extrabold text-[20px] md:text-[14px] xsm:text-[8px]">Lifestyle Store</p>
              </div>
              <div className="flex flex-col items-center gap-6 p-6 pt-8 border-r md:gap-4 md:p-4 xsm:gap-3 xsm:p-2">
                <div className="overflow-hidden flex justify-end rounded-2xl w-[350px] h-[600px] md:w-[200px] md:h-[400px] xsm:w-[110px] xsm:h-[190px] bg-[#F5F2FB] xsm:rounded-lg">
                  <ReactPlayer url={Video3} width={'96%'} height={'100%'} playing={true}  loop muted={true}/>
                </div>
                <p className="font-int font-extrabold text-[20px] md:text-[14px] xsm:text-[8px]">Home Air Conditioner</p>
              </div>
              <div className="flex flex-col items-center gap-6 p-6 pt-8 md:gap-4 md:p-4 xsm:gap-3 xsm:p-2">
                <div className="overflow-hidden rounded-2xl w-[350px] h-[600px] md:w-[200px] md:h-[400px] xsm:w-[110px] xsm:h-[190px] bg-[#BD826D] xsm:rounded-lg">
                  <ReactPlayer url={Video4} width={'100%'} height={'100%'} playing={true}  loop muted={true}/>
                </div>
                <p className="font-int font-extrabold text-[20px] md:text-[14px] xsm:text-[8px]">Interface Market</p>
              </div>
            </div>
          }
        </div>
      </GridLines>
    </main>
  );
};

export default AllProjects;
