import React, { useState } from "react";

const DevelopmentProcess = () => {
  const [currentStep, setCurrentStep] = useState(1);

  const stepsContent = [
    {
      title: "Vision Blueprinting",
      description:
        "At the outset, our team meticulously analyzes your goals, crafting a roadmap that outlines milestones for your digital journey, ensuring clarity and strategy towards achieving your tech aspirations."
    },
    {
      title: "Design Alchemy",
      description:
        "Guided by innovation, our experts transform abstract ideas into captivating digital experiences, blending technology and finesse to breathe life into your vision, resonating with your audience."
    },
    {
      title: "Code Engineering",
      description:
        "With unwavering precision, we lay robust foundations for your digital solution, harnessing cutting-edge tech and adhering to rigorous standards for scalability, security, and performance excellence."
    },
    {
      title: "Quality Ques",
      description:
      "At every stage, quality is our priority. We subject your solution to comprehensive testing, ensuring flawless performance and user satisfaction, leaving no stone unturned in our pursuit of excellence."
    },
    {
      title: "Launch Pad Activation",
      description:
      "We orchestrate a seamless, impactful launch, leveraging expertise to propel your solution into the digital stratosphere, maximizing visibility, engagement, and success in the digital realm."
    },
    {
      title: "Path to Innovation",
      description:
      "Beyond launch, our commitment persists. We embark on a journey of continuous innovation, exploring new technologies and trends to enhance your digital presence, delivering ongoing value and relevance to your audience."
    }
  ];

  const handleStepClick = (stepNumber) => {
    setCurrentStep(stepNumber);
  };


  return (
    <div className="flex flex-col gap-16 font-int justify-center items-center px-[6%] pb-[6%] xsm:gap-8 xsm:pt-[5%] xsm:pb-[16%]">
      <div className="flex flex-col gap-4 justify-center items-center xsm:gap-1 md:gap-2">
        <p className="capitalize text-[50px] font-semibold xsm:text-[20px] md:text-[36px]">
          Our  Development Process
        </p>
        <p className="text-[22px] w-[80%] text-center xsm:text-[9px] md:text-[16px]">
        Turning your visionary concepts into tangible digital solutions, we guide you through each step of the process, ensuring clarity, precision, and alignment with your goals every step of the way.
        </p>
      </div>

      <div className="flex flex-col gap-8 w-full xsm:gap-6 md:gap-6">
        <div className="grid grid-cols-6 text-center items-center text-[20px]">
        {stepsContent.map((step, index) => (
            <p key={index} onClick={() => handleStepClick(index + 1)} className={currentStep === index + 1 ? "cursor-pointer text-[18px] xsm:text-[8px] md:text-[12px]" : "cursor-pointer text-[18px] xsm:text-[7px] md:text-[12px]"}>
              {step.title}
            </p>
          ))}
        </div>
        <div className="flex flex-col justify-center  w-full bg-black rounded-t-3xl min-h-[350px] relative dev-div xsm:min-h-[130px] xsm:rounded-xl md:min-h-[250px]">
          <div className="flex flex-row justify-around text-black text-[20px] font-robo absolute w-full -top-5 xsm:-top-2">
            {[1, 2, 3, 4, 5, 6].map((stepNumber) => (
              <div
                key={stepNumber}
                onClick={() => handleStepClick(stepNumber)}
                className={`flex justify-center font-robo items-center bg-[#A5C5F6] w-[52px] h-[52px] rounded-full outline outline-4 outline-black cursor-pointer xsm:w-[20px] xsm:h-[20px] xsm:text-[9px] md:w-[40px] md:h-[40px] md:text-[14px] ${
                  currentStep === stepNumber ? "bg-[#5395f7] font-bold" : ""
                }`}
              >
                {stepNumber}
              </div>
            ))}
          </div>
          <div className="flex flex-col items-start gap-8 px-[6%] xsm:gap-2 md:gap-4">
            <p className="text-[30px] font-bold xsm:text-[14px] md:text-[22px]">
              {stepsContent[currentStep - 1].title}
            </p>
            <p className="text-[22px] font-light xsm:text-[9px] md:text-[18px]">
              {stepsContent[currentStep - 1].description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DevelopmentProcess;
