import React from "react";
import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "./CustomerSection.css";
import "../Animation/Glow.css";
import Icon1 from "../../Assests/Icons/Customericon1.svg";
import Icon2 from "../../Assests/Icons/Customericon2.svg";
import Icon3 from "../../Assests/Icons/Customericon3.svg";
import Icon4 from "../../Assests/Icons/Customericon4.svg";

const CustomerSection = () => {
  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  return (
    <main id="main">
      <span id="round" ref={roundRef}></span>
      <div className="bg-[#000] text-[#FFFFFB] font-int ">
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className="pt-[6%]  gridglow xsm:px-[5%] xsm:pt-[15%]">
            <div className="px-[8%] space-y-4">
              <p className="capitalize text-center font-int font-bold text-[50px] text-[#9EC1F5] xsm:text-[16px] md:text-[30px]">
              Choose Us, Experience the Difference
              </p>
              <p className="text-[#FFFFFF] px-[8%] flex justify-center items-center w-full  text-center text-[22px] capitalize xsm:text-[9px] xsm:pt-3 md:text-[14px]">
              Choose HMCodelab for unparalleled innovation and dedicated support. Experience the difference with our expert solutions and exceptional customer care.
              </p>
            </div>
            <div className="grid grid-cols-4 px-[8%] gap-14 mt-[5%] xsm:px-[0%] xsm:gap-4 md:gap-6">
              <div className="flex flex-col items-center justify-between gap-3 CustomerSectionGradientColor bg-gradient-to-b from-[#010003] to-[#0C091C] px-6 py-14  rounded-3xl xsm:p-2 xsm:rounded-xl xsm:gap-2 md:p-4">
                <img className="w-20 xsm:w-5 md:w-12" src={Icon1} alt="" />
                <h1 className="text-center font-bold text-[22px] leading-[1.1] xsm:text-[8px] md:text-[16px]">
                Excellence in <br/>Every Code
                </h1>
                <p className="text-center text-[#D9D9D9] text-[15px] xsm:text-[7px] md:text-[12px]">
                Crafting the future, one line of code at a time. We don't just meet expectations—we redefine them.
                </p>
              </div>
              <div className="flex flex-col items-center justify-between gap-3 CustomerSectionGradientColor bg-gradient-to-b from-[#010003] to-[#0C091C] px-6 py-14  rounded-3xl xsm:p-2 xsm:rounded-xl xsm:gap-2 md:p-4">
                <img className="w-[84px] xsm:w-5 md:w-12" src={Icon2} alt="" />
                <h1 className="text-center font-bold text-[22px] leading-[1.1] xsm:text-[8px] md:text-[16px]">
                Propel <br/> Forward
                </h1>
                <p className="text-center text-[#D9D9D9] text-[15px] xsm:text-[7px] md:text-[12px]">
                Embrace the future with our AI-driven solutions. We're preparing you for tomorrow, today.
                </p>
              </div>
              <div className="flex flex-col items-center justify-between gap-3 CustomerSectionGradientColor bg-gradient-to-b from-[#010003] to-[#0C091C] px-6 py-14  rounded-3xl xsm:p-2 xsm:rounded-xl xsm:gap-2 md:p-4">
                <img className="w-[74px] xsm:w-5 md:w-12" src={Icon3} alt="" />
                <h1 className="text-center font-bold text-[22px] leading-[1.1] xsm:text-[8px] md:text-[16px]">
                Meet Our <br/> Wizards
                </h1>
                <p className="text-center text-[#D9D9D9] text-[15px] xsm:text-[7px] md:text-[12px]">
                Your tech journey is guided by our pioneering experts, leading you through new terrains of innovation.
                </p>
              </div>
              <div className="flex flex-col items-center justify-between gap-3 CustomerSectionGradientColor bg-gradient-to-b from-[#010003] to-[#0C091C] px-6 py-14  rounded-3xl xsm:p-2 xsm:rounded-xl xsm:gap-2 md:p-4">
                <img className="w-[72px] xsm:w-5 md:w-12" src={Icon4} alt="" />
                <h1 className="text-center font-bold text-[22px] leading-[1.1] xsm:text-[8px] md:text-[16px]">
                Always On <br/> Support
                </h1>
                <p className="text-center text-[#D9D9D9] text-[15px] xsm:text-[7px] md:text-[12px]">
                Night or day, our support team is just a call away, ready to assist you whenever inspiration strikes.
                </p>
              </div>
            </div>
          </div>
        </GridLines>
      </div>
    </main>
  );
};

export default CustomerSection;
