import React from "react";
import { Link } from "react-router-dom";
import "./CollabSection.css";
import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import GroupImg from "../../Assests/Images/CollabimgG1.png";
import PhoneImg1 from "../../Assests/Images/CollabG2img1.png";
import PhoneImg2 from "../../Assests/Images/CollabG2img2.png";
import TextImg from "../../Assests/Images/Collabtext.png";
import ScrollImg from "../../Assests/Images/Project2img3.png"
import { ReactComponent as Arrow } from "../../Assests/Icons/collabarrow.svg";

const CollabSection = () => {
  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  return (
    <main id="main">
      <span id="round" ref={roundRef}></span>
      <div className="bg-[#000] text-[#FFFFFB] font-int">
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className="grid grid-cols-[45%,55%] pt-[7%] gridglow">
            {/* 1st Left */}
            <div className="pl-[33%] h-full md:pl-[25%]">
              <div className="w-full flex md:gap-8 justify-between items-center py-[10%] pr-[5%] border-b-[1.5px] border-r-[1.5px] border-[#72716B] h-full">
                <div className="relative w-full xsm:pr-4 mt-[15%]">
                  <img className="relative z-20" src={GroupImg} alt="" />
                  <div className="absolute top-[3.9%] left-8 z-20 rotate-1 skew-y-[2deg] rounded-2xl overflow-hidden h-[395px] w-[170px] xsm:rounded-lg xsm:h-[90%] xsm:w-[60%] xsm:left-[11%] md:h-[90%] md:w-[62%]">
                    <img className="w-full animateScroll xsm:object-cover md:object-cover"  src={ScrollImg} alt="" />
                  </div>
                </div>
                <img className="h-max xsm:h-32 md:h-96 md:pt-16" src={TextImg} alt="" />
              </div>
            </div>
            {/* 1st Container content */}
            <div className="h-full border-b-[1.5px] border-[#72716B] py-[3%] pb-[5%]">
              <div className="flex flex-col w-[85%] pl-[7%] pt-[3%]">
                <div className="flex flex-col gap-6 xsm:gap-3">
                  <h1 className="font-bold text-[40px] leading-tight xsm:text-[12px] md:text-[36px]">
                  Discover the Winning Combination: SEO and Web Design
                  </h1>
                  <p className="text-[#F9F6E0] text-justify text-[20px] xsm:text-[8px] md:text-[14px]">
                  Ever wondered why 98% of our website traffic originates from Google? It's not by chance—it's strategic. At our studio, we understand that SEO isn't an afterthought; it's the foundation of effective web design. By seamlessly integrating search engine optimization into every facet of our designs, we don't just create visually stunning websites—we ensure they dominate search engine rankings. This means your site not only looks great but also attracts organic traffic effortlessly, saving you from expensive advertising campaigns. Ready to elevate your online presence? Let's collaborate and transform your website into a powerhouse that captivates users and search engines alike.
                  </p>
                </div>
              </div>
            </div>
            {/* 2nd Left */}
            <div className="pl-[33%] w-full h-full xsm:pl-[25%]">
              <div className="flex justify-between items-center py-[7%] pr-[5%] h-full border-r-[1.5px] border-[#72716B] w-full">
                <div className="translate-y-[10%] translate-x-[5%] flex h-full w-full">
                  <div className="animate-bounce w-full h-full">
                    <img
                      className="-rotate-[8.2deg] w-[200px] h-[75%] md:h-[50%] md:w-[100%] xsm:h-[60%]"
                      src={PhoneImg1}
                      alt=""
                    />
                  </div>
                  <div className="animate-bounce-down w-full h-full">
                    <img
                      className="rotate-[7.25deg] translate-y-20 -translate-x-14 w-[200px] h-[75%] md:h-[50%] md:w-[100%] xsm:translate-y-8 xsm:-translate-x-6 xsm:h-[60%]"
                      src={PhoneImg2}
                      alt=""
                    />
                  </div>
                </div>
                <div className="w-[10%]">
                  <img className="h-max w-max translate-y-[7%] xsm:h-32 md:h-80 md:translate-y-[15%]" src={TextImg} alt="" />
                </div>
              </div>
            </div>
            {/* 2nd Container content */}
            <div className="flex flex-col w-[85%] pl-[7%] py-[3%]">
              <div className="flex flex-col gap-6 xsm:gap-4">
                <h1 className="font-bold text-[40px] leading-tight xsm:text-[12px] md:text-[36px]">
                Breaking the Agency Mold: Tech Evolution Starts Here!
                </h1>
                <p className="text-[#F9F6E0] text-justify text-[20px] leading-tight text-balance xsm:text-[8px] md:text-[14px]">
                Welcome to HM CodeLab—where innovation reigns supreme! We're not your average agency; we're trailblazers of tech evolution. With a decade of expertise, we've shattered norms and fused creativity with cutting-edge technology. Our mission? Redefining tech companies by delivering groundbreaking solutions that drive businesses forward. From custom software to immersive digital experiences, we push boundaries and unlock technology's full potential. Join our journey as we revolutionize the digital landscape, one innovation at a time. Step into a world without limits—welcome to HM CodeLab.
                </p>
              </div>
              <div className="mt-[13%] flex flex-col gap-8 xsm:gap-5">
                <Link to='/services' className="bg-[#9EC1F5] text-[16px] text-[#000] font-int font-bold rounded-md flex flex-row gap-2 justify-center items-center py-3 px-5 w-max cursor-pointer xsm:text-[7px] xsm:gap-1 xsm:px-2 xsm:py-1 xsm:rounded-sm md:text-[12px] md:px-4 md:py-2">
                  HOW THIS WORKS <Arrow className="w-3 h-3 xsm:w-2 xsm:h-2" />
                </Link>
              </div>
            </div>
          </div>
        </GridLines>
      </div>
    </main>
  );
};

export default CollabSection;
