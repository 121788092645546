import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import "./whychoose.css";
import GridLines from "react-gridlines";
import "../Animation/Glow.css";

export default function Whychooseus() {
  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  const massiveImageRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    // Refresh ScrollTrigger to ensure proper initialization
    ScrollTrigger.refresh();

    // Set up GSAP animation with ScrollTrigger for the content
    gsap.to(massiveImageRef.current, {
      xPercent:
        window.innerWidth <= 600
          ? -100
          : window.innerWidth >= 2000
          ? -107
          : -100, //change these as the content of the slider increases
      x: () => window.innerWidth,
      ease: "none",
      scrollTrigger: {
        trigger: massiveImageRef.current,
        start: window.innerWidth <= 600 ? "0%" : "0%", //xsm:-140%
        end: () => (window.innerWidth <= 600 ? "35%" : "65%"), //window.innerWidth
        scrub: true,
        pin: true,
        anticipatePin: 1,
        invalidateOnRefresh: true, // Ensure proper refresh of ScrollTrigger
      },
    });

    return () => {
      // Clean up ScrollTrigger on component unmount
      ScrollTrigger.getAll().forEach((trigger) => trigger.kill());
    };
  }, []);

  return (
    <>
      <main id="main  ">
        <span id="round" ref={roundRef}></span>
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className="flex h-[279vh]   overflow-hidden gridglow xsm:h-[150vh]">
            <div className="relative" ref={massiveImageRef}>
              <div className="flex">
                <div className="w-[100vw] h-[100vh] 2xl:w-[70vw] ">
                  <div className="flex justify-center items-center h-full">
                    <div className="flex flex-col text-center font-int font-extrabold">
                      <div className="text-8xl text-white my-auto xsm:text-[50px] md:text-6xl">
                        Why
                      </div>
                      <div className="text-8xl text-white my-auto xsm:text-[50px] md:text-6xl">
                        Choose us?
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-[100vw] h-[100vh]   2xl:w-[70vw] text-white  xsm:w-[200vw]">
                  <div className="flex justify-between items-center h-full px-[5%] md:px-[2%]">
                    <div className="flex flex-col  justify-start gap-40  h-full  py-14  w-[30%] xsm:w-[40%] xsm:justify-evenly md:w-[35%] md:justify-evenly">
                      <div className="flex flex-col gap-2 font-semibold xsm:gap-4">
                        <div className=" text-white text-4xl xsm:text-[30px] md:text-3xl">
                          Why Choose
                        </div>
                        <div className=" text-white text-4xl xsm:text-[30px] md:text-3xl">
                        HM CODELAB?
                        </div>
                      </div>
                      <div className=" text-left text-xl xsm:text-[20px] md:text-[18px]">
                      At HM CODELAB, we deliver innovative, tailored solutions using cutting-edge technology. Our strategic, audience-focused approach ensures your projects lead in a dynamic market, turning challenges into opportunities that drive your success.
                      </div>
                    </div>

                    <div className="w-[50%] h-full flex flex-col justify-evenly gap-10 xsm:w-[50%] xsm:justify-evenly md:w-[60%]">
                      <div className="flex gap-x-14 ml-24 xsm:ml-14 xsm:gap-x-6 xsm:items-center md:gap-x-10">
                        <div className="h-32 w-32 rounded-full border-[8px] border-[#9EC1F5] flex justify-center items-center text-white  whychoosecircle text-3xl font-semibold xsm:text-[22px] xsm:w-24 xsm:h-24 xsm:border-[5px] md:text-[22px] md:h-28 md:w-28 md:border-[6px]">
                          100%
                        </div>

                        <div className="flex flex-col w-[50%] font-int justify-center gap-2 xsm:w-[65%]">
                          <div className="text-[#9EC1F5] text-xl font-semibold uppercase xsm:text-[16px] md:text-[18px]">
                          Innovate with Impact
                          </div>
                          <div className="xsm:text-[12px] md:text-[14px]">
                          At HM CODELAB, we don’t just adapt to change, we drive it. Our cutting-edge solutions turn your challenges into wins, setting new standards in your industry.
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-x-14 xsm:gap-x-6 xsm:items-center md:gap-x-10">
                        <div className="h-32 w-32 rounded-full border-[8px] border-[#9EC1F5] flex justify-center items-center text-white  whychoosecircle text-3xl font-semibold xsm:text-[22px] xsm:w-24 xsm:h-24 xsm:border-[5px] md:text-[22px] md:h-28 md:w-28 md:border-[6px]">
                          100%
                        </div>

                        <div className="flex flex-col w-[50%] font-int justify-center gap-2 xsm:w-[65%]">
                          <div className="text-[#9EC1F5] text-xl uppercase font-semibold xsm:text-[16px] md:text-[18px]">
                          Strategic Success
                          </div>
                          <div className="xsm:text-[12px] md:text-[14px]">
                          Tailored strategies mean targeted results. We understand your unique needs, crafting bespoke solutions that amplify your market impact and operational efficiency.
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-x-14 ml-24 xsm:ml-14 xsm:gap-x-6 xsm:items-center md:gap-x-10">
                        <div className="h-32 w-32 rounded-full border-[8px] border-[#9EC1F5] flex justify-center items-center text-white  whychoosecircle text-3xl font-semibold xsm:text-[22px] xsm:w-24 xsm:h-24 xsm:border-[5px] md:text-[22px] md:h-28 md:w-28 md:border-[6px]">
                          100%
                        </div>

                        <div className="flex flex-col w-[50%] font-int justify-center gap-2 xsm:w-[65%]">
                          <div className="text-[#9EC1F5] text-xl uppercase font-semibold xsm:text-[16px] md:text-[18px]">
                          Client-First Approach
                          </div>
                          <div className="xsm:text-[12px] md:text-[14px]">
                          Your vision is our blueprint. We work closely with you every step of the way, ensuring our solutions not only meet but exceed your expectations.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="w-[100vw] h-[100vh] 2xl:w-[]   flex flex-col justify-around gap-y-9 items-center py-10 xsm:justify-evenly ">
                  <div className="text-center text-white font-semibold text-[34px] xsm:text-[20px] md:text-[20px]">
                  Got an Idea? Let’s Innovate Together!
                  </div>
                  <div className="  text-[#9EC1F5] text-[70px] w-[70%] text-center font-semibold xsm:text-[40px] xsm:w-[95%] md:text-[60px]">
                  Transform Your Vision into Reality with HM CODELAB
                  </div>
                  <Link
                    to="/getintouch"
                    className="group group-hover:before:duration-500 mix-blend-overlay group-hover:after:duration-500 after:duration-500 hover:before:[box-shadow:_20px_20px_20px_30px_#B2BEFB80] before:[box-shadow:_20px_20px_20px_40px_#B2BEFB90] duration-500 before:duration-500 hover:duration-500 hover:after:-right-40 hover:before:right-12 hover:before:bottom-8 hover:before:[#6C78D820] origin-left relative bg-neutral-800 h-56 w-56 border-[4px] border-gray-600 text-center text-white rounded-full  overflow-hidden  before:absolute before:w-44 before:h-44 before:content[''] before:right-8 before:top-8 before:z-10 before:bg-[#6C78D860] before:rounded-full before:blur-lg  after:absolute after:z-10 after:w-44 after:h-44 after:content['']  after:bg-[#6C78D870] after:right-5 after:top-1 after:rounded-full after:blur-lg xsm:h-44 xsm:w-44"
                  >
                    <span className="text-4xl z-20 absolute flex items-center justify-center inset-0 xsm:text-xl uppercase">
                    Get <br /> Started
                    </span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </GridLines>
      </main>
    </>
  );
}
