import { useEffect, useRef, useState } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "../OurServices/OurServices.css";
import OuterCircle from "../../Assests/Images/getintouchimg1.png";
import InnerCircle from "../../Assests/Images/getintouchimg2.png";
import axios from "axios";
import toast from "react-hot-toast";
import "./getintouch.css";
import { BASE_URL } from "../api";
import { Helmet } from "react-helmet";
const GetInTouch = () => {
  const [btnLoader, setBtnLoader] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [num, setNum] = useState("");
  const [orgName, setOrgName] = useState("");
  const [lookingFor, setLookingFor] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    userEmail: "",
    phone: "",
    text: ""
  });
  // const [budget, setBudget] = useState("");
  // const [projectDesc, setProjectDesc] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setBtnLoader(true);

    // Needs these three value from emailJS for the Hoping the Email on which You want to receive the Email
    const serviceId = "service_b3baalu";
    const templateId = "template_a63znzd";
    const publicKey = "ieUud1LM0yZKCFZON";

    const data = {
      service_id: serviceId,
      template_id: templateId,
      user_id: publicKey,
      template_params: {
        to_name: "Hoping Minds",
        from_name: name,
        from_email: email,
        person_number: num,
        org_name: orgName,
        looking_for: lookingFor,
        // budget:budget,
        // project_desc:projectDesc,
      },
    };
    if (
      name === "" ||
      email === "" ||
      num === "" ||
      orgName === "" ||
      lookingFor === ""
      // budget === "" ||
      // projectDesc === ""
    ) {
      toast.error("Field cannot be empty");
      setBtnLoader(false);
    } else {
      try {
        const res = await axios.post(
          "https://api.emailjs.com/api/v1.0/email/send",
          data
        );
        console.log(res.data);
        setName("");
        setEmail("");
        setNum("");
        setOrgName("");
        setLookingFor("");
        // setBudget("");
        // setProjectDesc("");
        toast.success("Message Sent Successfully");
      } catch (error) {
        console.error(error);
        toast.error("Fails to Sent Message Successfully");
      } finally {
        setBtnLoader(false);
      }
    }
  };

  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const thresholdPosition = window.innerWidth <= 480 ? 100 : 300;

      if (scrollPosition > thresholdPosition) {
        setShowPopup(true);
      } else {
        setShowPopup(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(BASE_URL+'registerMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept':"application/json",
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      setFormData({
        username: "",
        userEmail: "",
        phone: "",
        text: ""
      });

      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <main id="main">
      <Helmet>
        <title>HM CodeLab - Get In Touch</title>
        <meta name="description" content="Collaborate with HM CodeLab and turn your innovative ideas into reality. We work closely with clients to deliver tailored digital solutions that exceed expectations. Share your vision with us and let's create something amazing together. Visit Get In Touch with HM CodeLab to start your tech adventure today." data-react-helmet="true"/>
        <meta property="og:title" content="HM CodeLab - Get In Touch" data-react-helmet="true"/>
        <meta property="og:type" content="website" data-react-helmet="true"/>
        <meta property="og:image" content="/og_image_getintouch.png" data-react-helmet="true"/>
        <meta property="og:image:width" content="526" data-react-helmet="true"/>
        <meta property="og:image:height" content="275" data-react-helmet="true"/>
        <meta property="og:image:type" content="image/png" data-react-helmet="true"/>
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
        <meta property="og:description" content="Collaborate with HM CodeLab and turn your innovative ideas into reality. We work closely with clients to deliver tailored digital solutions that exceed expectations. Share your vision with us and let's create something amazing together. Visit Get In Touch with HM CodeLab to start your tech adventure today." data-react-helmet="true"/>
        <meta property="og:site_name" content="HM CodeLab - Get In Touch" data-react-helmet="true"/>
      </Helmet>
      <span id="round" ref={roundRef}></span>
      <div className="bg-black font-int text-white">
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className=" px-[7.5%] pb-[7%] pt-[2%] grid-glow xsm:px-[5%]">
            <div className="flex flex-col xsm:gap-4 md:gap-4">
              <div className="flex justify-between items-center">
                <div className="w-[55%] leading-tight">
                  <p className="text-[70px] text-white font-bold xsm:text-[25px] md:text-[40px] capitalize">
                    Collaborate for{" "}
                    <span className="text-[#9EC1F5] text-[70px] font-bold  xsm:text-[25px] md:text-[40px]">
                      Greatness
                    </span>
                  </p>
                </div>
                <div className="w-[250px] h-[250px] rounded-full flex justify-center items-center relative pt-24 md:pt-2 xsm:h-[80px] xsm:w-[80px] xsm:pt-3 md:w-[155px] md:h-[155px]">
                  <div className="absolute">
                    <img
                      className="animate-spin xsm:w-full xsm:h-full"
                      src={OuterCircle}
                      alt=""
                    />
                  </div>
                  <img
                    className="z-10 xsm:w-8 xsm:h-8 md:w-16 md:h-16"
                    src={InnerCircle}
                    alt=""
                  />
                </div>
              </div>
              <div className="w-[50%] xsm:w-full md:w-full">
                <p className="text-white text-[20px] xsm:text-[11px] md:text-[18px]">
                  At HM CodeLabs, we are passionate about turning innovative
                  ideas into reality. We work closely with our clients to
                  understand their needs and deliver tailored solutions that
                  exceed expectations. Share your vision with us, and let’s
                  create something amazing together!
                </p>
              </div>
            </div>
            <div className="w-[75%] mt-[7%] xsm:w-full md:w-full">
              {/* form */}
              <div className="flex flex-col gap-8 xsm:gap-4 md:gap-4">
                <div className="flex flex-col gap-1 ">
                  <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                    <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px] ">
                      01
                    </p>
                    <p className="font-medium text-[30px] xsm:text-[15px] md:text-[26px]">
                      Full Name
                    </p>
                  </div>
                  <div className="ml-[10%]">
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      value={name}
                      placeholder="Please enter your full name."
                      className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                    <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">
                      02
                    </p>
                    <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">
                      Email Address
                    </p>
                  </div>
                  <div className="ml-[10%]">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="text"
                      value={email}
                      placeholder="Enter your email to stay connected."
                      className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                    <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">
                      03
                    </p>
                    <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">
                      Phone Number
                    </p>
                  </div>
                  <div className="ml-[10%]">
                    <input
                      onChange={(e) => setNum(e.target.value)}
                      type="text"
                      value={num}
                      placeholder="Your contact number for direct communication."
                      className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                    />
                  </div>
                </div>
                <div className="flex flex-col gap-1 ">
                  <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                    <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">
                      04
                    </p>
                    <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">
                      Company/Organization Name
                    </p>
                  </div>
                  <div className="ml-[10%]">
                    <input
                      onChange={(e) => setOrgName(e.target.value)}
                      type="text"
                      value={orgName}
                      placeholder="The name of your business or organization."
                      className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                    />
                  </div>
                </div>
                {/* Has no state */}
                <div className="flex flex-col gap-1 ">
                  <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                    <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">
                      05
                    </p>
                    <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">
                      Service Required
                    </p>
                  </div>
                  <div className="ml-[10%]">
                    <input
                      onChange={(e) => setLookingFor(e.target.value)}
                      type="text"
                      value={lookingFor}
                      placeholder="Specify the service you are interested in."
                      className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                    />
                  </div>
                </div>
                {/* <div className="flex flex-col gap-1 ">
                <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                  <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">06</p>
                  <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">Budget</p>
                </div>
                <div className="ml-[10%]">
                  <input
                    onChange={(e) => setBudget(e.target.value)}
                    type="text"
                    value={budget}
                    placeholder="Your estimated budget for the project."
                    className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                  />
                </div>
              </div> */}
                {/* <div className="flex flex-col gap-1 ">
                <div className="flex items-center gap-6 xsm:gap-2 md:gap-3">
                  <p className=" border w-max h-max text-white text-center text-[28px] px-3 py-[2px] font-semibold rounded-full leading-none xsm:text-[14px] xsm:px-1 xsm:py-[1px] md:text-[24px]">07</p>
                  <p className="font-medium text-[30px] xsm:text-[15px]  md:text-[26px]">Project Details</p>
                </div>
                <div className="ml-[10%]">
                  <textarea
                    onChange={(e) => setProjectDesc(e.target.value)}
                    type="text"
                    value={projectDesc}
                    placeholder="Describe your project to help us understand your requirements better."
                    className="outline-none placeholder:italic placeholder:text-[#3C4049] bg-transparent border-b border-[#D9D9D9] w-full text-[20px] text-white py-2 xsm:text-[10px] xsm:py-1 md:text-[14px]"
                  />
                </div>
              </div> */}
              </div>
              <div className="mt-20 xsm:mt-6 xsm:justify-center flex md:mt-10 md:justify-center">
                <button
                  onClick={handleSubmit}
                  className=" w-max px-8 py-2 border font-medium text-[28px] rounded-full xsm:text-[16px] xsm:px-4 xsm:py-1 md:text-[22px]"
                >
                  {btnLoader ? "Loading..." : "Send Message"}
                </button>
              </div>
            </div>
          </div>
        </GridLines>
      </div>
      {showPopup && (
        <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white py-4 px-6 drop-shadow-xl rounded-l-md xsm:py-2 xsm:px-4 xsm:bottom-6 md:bottom-8 md:py-3 md:px-5">
          <div className="login-box">
            <p>Let’s Build Something Great Together .</p>
            <form onSubmit={handleSubmitForm}>
              <div className="user-box">
                <input required name="username" type="text" value={formData.username}
                  onChange={handleChange} />
                <label>Username</label>
              </div>
              <div className="user-box">
                <input required name="userEmail" type="text"  value={formData.userEmail}
                  onChange={handleChange} />
                <label>Business Email</label>
              </div>
              <div className="user-box">
                <input required name="phone" type="text" value={formData.phone}
                  onChange={handleChange} />
                <label>Phone Number</label>
              </div>
              <div className="user-box">
                <input required name="text" type="message" value={formData.text}
                  onChange={handleChange} />
                <label>Whats Your Ideas</label>
              </div>
              <div onClick={handleSubmitForm} className="flex justify-center items-center">
              <a href="#">
                <span />
                <span />
                <span />
                <span />
                Submit
              </a>
              </div>
            </form>
          </div>
        </div>
      )}
    </main>
  );
};

export default GetInTouch;
