import React, { useState, useEffect } from "react";
import "./CardsSwap.css";
import User from "../../Assests/Images/card-user.png";
import Profile1 from "../../Assests/Images/homeprofielimg1.png";
import Profile2 from "../../Assests/Images/homeprofielimg2.png";
import Profile3 from "../../Assests/Images/homeprofielimg3.png";

const StackedCards = () => {
  const [currentCard, setCurrentCard] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentCard((currentCard) =>
        currentCard === 3 ? 1 : currentCard + 1
      );
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stacked-cards-container">
      <div className={`card card-${currentCard === 1 ? 1 : currentCard - 1}`}>
        <span className="rounded-full w-8 h-8 absolute -translate-y-7 -translate-x-7 xsm:-translate-x-4 xsm:-translate-y-6"><img src={Profile2} className="w-full h-full object-cover rounded-full xsm:w-6 xsm:h-6 md:w-8 md:h-8" /></span>
        “Incredible software! It transformed our operations overnight, making complex tasks effortless. A game-changer for our business!”
        <div className="flex flex-col">
          <p className="font-semibold">Ankit Sharma</p>
          <p>Mumbai, India</p>
        </div>
      </div>
      <div className={`card card-${currentCard === 3 ? 1 : currentCard + 1}`}>
      <span className="rounded-full w-8 h-8 absolute -translate-y-7 -translate-x-7 xsm:-translate-x-4 xsm:-translate-y-6"><img src={Profile3} className="w-full h-full object-cover rounded-full xsm:w-6 xsm:h-6 md:w-8 md:h-8" /></span>
        “Revolutionized our workflow! This software is intuitive, efficient, and a true game-changer for our team. Highly recommended!”
        <div className="flex flex-col">
          <p className="font-semibold">Suhana Verma</p>
          <p>Delhi, India</p>
        </div>
      </div>
      <div className={`card card-${currentCard}`}>
      <span className="rounded-full w-8 h-8 absolute -translate-y-7 -translate-x-7 xsm:-translate-x-4 xsm:-translate-y-6"><img src={Profile1} className="w-full h-full object-cover rounded-full xsm:w-6 xsm:h-6 md:w-8 md:h-8" /></span>
        “Exceptional service! Their software solutions exceeded our expect-ations, streamlining our processes and boosting produ-ctivity.”
        <div className="flex flex-col">
          <p className="font-semibold">Riya Bhatt</p>
          <p>Cu
            Chandigarh, India</p>
        </div>
      </div>
    </div>
  );
};

export default StackedCards;
