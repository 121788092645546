import React from 'react'
import { useNavigate } from 'react-router-dom'
import Img from "../../Assests/Images/404img.png"
const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <div className=" overflow-hidden flex items-center justify-center bg-[#000] font-int">
            <div className="lg:py-24 md:py-20 md:px-44 px-32 py-24 items-center flex justify-center xsm:flex-col-reverse md:flex-col-reverse lg:flex-row md:gap-28 gap-16">
                <div className="xl:pt-24 w-full xl:w-1/2 relative pb-12 lg:pb-0">
                    <div className="relative">
                        <div className="">
                            <div className="flex flex-col items-center">
                                <h1 className="my-2 text-white font-bold text-2xl">
                                    Looks like you've found the
                                    doorway to the great nothing
                                </h1>
                                <p className="my-2 text-white">Please visit our hompage to get where you need to go.</p>
                                <button onClick={() => navigate('/')} className="sm:w-full lg:w-auto my-2 border rounded md py-2 px-8 text-center bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-700 focus:ring-opacity-50 ">Take me there!</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <img src={Img} />
                </div>
            </div>
        </div>
    )
}

export default PageNotFound