import React, { useState } from "react";
import "./callbutton.css";
import whatsappIcon from "../../Assests/Icons/call.svg"; // Import the WhatsApp icon SVG file
import { BASE_URL } from "../api";

const CallButton = () => {
  const [showForm, setShowForm] = useState(false);
  const [formData, setFormData] = useState({
    userName: "",
    userEmail: "",
    phone: "",
    text: ""
  });

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    try {
      const response = await fetch(BASE_URL+'registerMail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept':"application/json",
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      console.log('Success:', data);
    } catch (error) {
      console.error('Error:', error);
    }
  };


  return (
    <div className="fixed -left-20 top-1/2 transform -translate-y-1/2 text-white -rotate-90 xsm:-left-12">
      <button className="button-call" onClick={toggleForm}>
        <div className="svg-wrapper-1">
          <div className="svg-wrapper">
            <img
              src={whatsappIcon}
              alt="WhatsApp Icon"
              width="24"
              height="24"
              className="rotate-90"
            />
          </div>
        </div>
        <span className="">Get a Call Back</span>
      </button>
      {showForm && (
        <div className="form-container absolute left-0 top-0 bg-gray-800 text-white p-4 rounded-lg rotate-90 z-[999]">
          <div class="flex flex-col items-center justify-center dark absolute -bottom-20 left-0 z-[999] xsm:bottom-0">
            <button
              onClick={toggleForm}
              className="absolute -top-2 -right-2 text-white bg-[#4dc7d9] hover:text-gray-300 w-8 h-8 rounded-full flex justify-center items-start text-center text-xl xsm:w-5 xsm:h-5 xsm:p-1"
            >
                <p className="text-white ">x</p>
            </button>
            <div class="w-[340px] max-w-lg bg-gray-800 rounded-lg shadow-md p-6 md:w-[250px] xsm:w-[150px] xsm:p-4">
              <h2 class="text-2xl font-bold text-gray-200 mb-4 md:text-[16px] xsm:text-[10px] xsm:mb-2">
                Discuss your idea with us
              </h2>
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <input
                  name="userName"
                  placeholder="Full Name"
                  className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150 md:text-[12px] xsm:text-[8px] xsm:p-1 xsm:mb-2"
                  type="text"
                  value={formData.userName}
                  onChange={handleChange}
                />
                <input
                  name="userEmail"
                  placeholder="Business Email"
                  className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150 md:text-[12px] xsm:text-[8px] xsm:p-1 xsm:mb-2"
                  type="email"
                  value={formData.userEmail}
                  onChange={handleChange}
                />
                <input
                  name="phone"
                  placeholder="Mobile Number"
                  className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150 md:text-[12px] xsm:text-[8px] xsm:p-1 xsm:mb-2"
                  type="text"
                  value={formData.phone}
                  onChange={handleChange}
                />
                <input
                  name="text"
                  placeholder="Please Share Your Business Requirement"
                  className="bg-gray-700 text-gray-200 border-0 rounded-md p-2 mb-4 focus:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-blue-500 transition ease-in-out duration-150 md:text-[12px] xsm:text-[8px] xsm:p-1 xsm:mb-2"
                  type="text"
                  value={formData.text}
                  onChange={handleChange}
                />

                <button
                  className="bg-gradient-to-r from-indigo-500 to-blue-500 text-white font-bold py-2 px-4 rounded-md mt-4 hover:bg-indigo-600 hover:to-blue-600 transition ease-in-out duration-150 md:text-[14px] xsm:text-[10px] xsm:mt-2 xsm:py-1 xsm:px-2"
                  type="submit"
                >
                  Call Me Now!
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CallButton;
