import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Home from "../Components/Home";
import ProjectsMain from "../Components/Projects/ProjectsMain";
import GetInTouch from "../Components/GetInTouch";
import OurServices from "../Components/OurServices";
import ScrollToTop from "../Components/ScrollToTop";
import PageNotFound from "../Components/404/PageNotFound";


const Router = () => {
  return (
    <BrowserRouter>
     <Header />
     <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/projects" element={<ProjectsMain />} />
        <Route path="/services" element={<OurServices />} />
        <Route path="/getintouch" element={<GetInTouch />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default Router;
