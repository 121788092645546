import React, { useEffect, useState } from 'react';
import { ReactComponent as Arrow } from '../../Assests/Icons/collabarrow.svg';
import Star from '../../Assests/Icons/yellow-star.png';
import bgImage from '../../Assests/Images/ReviewBG.png';
import './OurServices.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';

const Reviews = () => {

  const reviews = [
    {company: "Grocerify",
      manager: "Ishan Kapoor",
      designation: "Founder",
      description: "Absolutely thrilled with HM Codelab's website design for Grocerify! Their attention to detail and technical mastery truly brought our brand to life online. Highly recommend their exceptional service for top-tier web design and development. They've exceeded our expectations and made our vision a reality."
    },
    {company: "Hopingminds",
      manager: "Ishan Kapoor",
      designation: "Founder",
      description: "As Hoping Minds' founder, I'm thrilled with HM Codelab's exceptional work on our website. Their attention to detail and technical expertise shine through, capturing our essence perfectly. Highly recommend their exceptional service for anyone seeking top-notch web design and development. They've truly exceeded our expectations and brought our vision to life."
    },
    {company: "Appy High",
    manager: "Venus Dhuria",
    designation: "Founder",
    description: "Heading AppyHigh, I couldn't be happier with HM Codelab's website mastery. Their precision, tech finesse, and commitment to our vision were exceptional. I wholeheartedly endorse HM Codelab for their stellar web design and development services."
  },
  {company: "NFC card",
    manager: "Anuraag",
    designation: "Founder",
    description: "Thrilled with HM Codelab's website design for NC, our NFC card platform. Their attention to detail and technical finesse perfectly embodies our brand ethos. They've not only met but exceeded our expectations, flawlessly bringing our vision to life. Impressed by their dedication and expertise in web design and development."
  },
  {company: "One Impression",
    manager: "Apaksh Gupta",
    designation: "Founder",
    description: "As the visionary behind One Impression, I'm exhilarated by HM Codelab's transformative touch on our website. Their meticulous craftsmanship, technological finesse, and unwavering commitment to our brand vision have truly elevated our online presence. For unparalleled web design and development, I wholeheartedly endorse HM Codelab."
  },
  {company: "Astrotalk",
    manager: "Puneet Gupta",
    designation: "Founder",
    description: "As the owner of Astrotalk, I am absolutely thrilled with the exceptional work delivered by HM Codelab on our website. Their attention to detail, technical expertise, and dedication to bringing our vision to life have been truly outstanding. I highly recommend HM Codelab for their top-notch web design and development services."
  },
  ]

  // const [x, setX] = useState(window.scrollX); 
  // const [totalX, setTotalX] = useState(0); 
  // const [scrollBar, setScrollBar] = useState(window.innerWidth); 

  // useEffect(() => {
  //   const handleScroll = () => {
  //     setX(window.scrollX);
  //   };

  //   window.addEventListener('scroll', handleScroll);

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll);
  //   };
  // }, []);

  // useEffect(() => {
  //   const element = document.getElementsByClassName('Reviewsslider')[0];
  //   if (element) {
  //     setTotalX(element.clientWidth);
  //   }

  //   const handleResize = () => {
  //     setScrollBar(window.innerWidth);
  //   };

  //   window.addEventListener('resize', handleResize);

  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []); 

  // const scroll = totalX > 0 ? `${((x + scrollBar) / totalX) * 100}%` : '0%';

  return (
    <div className="font-int">
      {/* Heading */}
      <div className="flex items-center justify-between px-[7%] pb-4 border-b border-[#72716B] xsm:pb-1 md:pb-2">
        <div className='flex flex-col w-full'>
          <p className="text-[#FFFFFB] text-center capitalize font-bold text-[50px] xsm:text-[24px] md:text-[45px]">Hear Our Users Stories</p>
          <p className="text-[#F9F6E0] text-center text-[22px] xsm:text-[9px] md:text-[18px]">Exploring the Journeys of HM Codelab's Innovators </p>
        </div>
        <div>
        </div>
      </div>
      {/* Reviews */}
      <div className="overflow-x-auto mt-[3.5%] md:mt-[2%] pb-10 border-b border-[#72716B]">
        <Splide
          options={{
            type: "loop",
            // perPage: window.innerWidth <= 480 ? 2 : (window.innerWidth >= 721 && window.innerWidth <= 1024) ? 3 : 3,
            perPage: 3,
            fixedWidth : (window.innerWidth >= 20 && window.innerWidth <= 480) ? '12rem' : (window.innerWidth >= 721 && window.innerWidth <= 1024) ? '16rem' : '25rem',
            pagination: false,
            perMove: 1,
            wheel: false,
            arrows: true,
            interval: 3000,
            speed: 5000,
            delay: 0,
            pauseOnHover: false,
            drag: false,
            gap: "0.1rem",
          }}
        >
          {reviews.map((reviews, index) => (
          <SplideSlide>
              <div key={index} className="flex flex-col w-[95%] h-full justify-between gap-3 rounded-3xl px-8 py-8 xsm:px-[6px] xsm:py-1 xsm:rounded-lg xsm:gap-[6px] md:px-3 md:py-3 md:gap-1 md:rounded-xl" style={{ backgroundImage: `url(${bgImage})`, backgroundSize: 'cover' }}>
                <div className='flex flex-col gap-3 md:gap-2'>
                  <div className="flex gap-1 xsm:gap-[1px]">
                    {[...Array(5)].map((_, i) => (
                      <img key={i} src={Star} alt="Star" className='xsm:w-3 md:w-6' />
                    ))}
                  </div>
                  <div className="flex flex-col gap-3 xsm:gap-1 md:gap-1">
                    <p className="font-extrabold text-[20px] text-white xsm:text-[12px] md:text-[16px]">{reviews.company}</p>
                    <p className="font-extrabold text-[#889F9B] xsm:text-[9px] md:text-[11px]">{reviews.manager} ({reviews.designation})</p>
                    <p className="text-[#889F9B] text-justify text-[15px] xsm:text-[8px] md:text-[10px]">
                      {reviews.description}
                    </p>
                  </div>
                </div>
                {/* <div>
                  <p className="font-black text-[#9EC1F5] text-[14px] xsm:text-[6px] md:text-[9px]">/{(index + 1).toString().padStart(2, '0')}</p>
                </div> */}
              </div>
          </SplideSlide>
            ))}
        </Splide>
        
      </div>
      {/* Progress Bar */}
      {/* <div className="border-b border-[#72716B]">
        <div className="progress-bar mt-10 xsm:mt-5" style={{ width: scroll }}></div>
      </div> */}
    </div>
  );
};

export default Reviews;
