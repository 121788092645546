import React, { useState } from "react";
import "./Services.css";
import "../Animation/Glow.css";
import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import Img4 from "../../Assests/Images/service1.png";
import Img2 from "../../Assests/Images/service2.png";
import Img1 from "../../Assests/Images/service3.png";
import Img3 from "../../Assests/Images/service4.png";

const Services = () => {
  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  const [bgImage, setBgImage] = useState(Img1);
  const [heading, setHEADING] = useState();
  const [leave, setLeave] = useState();

  const handleHover = (image, num) => {
    setBgImage(image);
    setHEADING(num);
  };

  return (
    <main id="main">
      <span id="round" ref={roundRef}></span>
      <div className="bg-[#000] font-robo">
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className="flex flex-col gap-20 gridglow">
            <div className="flex flex-col gap-6 justify-center items-center font-Roboto px-[7%] xsm:pt-[8%] xsm:gap-4">
              <p className="text-[#9EC1F5] text-[50px] font-extrabold capitalize xsm:text-[16px] md:text-[36px]">
              Discover Our Tech Solutions
              </p>
              <p className="text-[22px] capitalize text-white text-center leading-6 w-[70%] xsm:text-[9px] xsm:leading-4 md:text-[16px]">
              From captivating website design to innovative app development and strategic marketing, we're your complete digital solution provider
              </p>
              <div
                className="border-[1px] w-full h-[60vh] mt-6 xsm:mt-2 xsm:h-[150px] md:h-[300px]"
                style={{
                  backgroundImage: `url(${bgImage})`,
                  backgroundSize: "cover",
                }}
              >
                <div className="grid grid-cols-4 divide-x-[1px] divide-white text-white font-Roboto h-full z-10">
                  {/* div-1 */}
                  <div
                    className="flex flex-col justify-end p-[5%] hover-parent hover:bg-black hover:bg-opacity-20 transition duration-300 overflow-hidden"
                    onMouseLeave={() => {
                      setHEADING(heading + 5);
                    }}
                    onMouseEnter={() => handleHover(Img1, 1)}
                  >
                    <p
                      className={`text-[24px] font-semibold capitalize leading-6 xsm:text-[10px] xsm:leading-none md:text-[16px] md:leading-none ${
                        heading == 1 ? "slide-top" : "slide-bottom"
                      } `}
                    >
                     Custom Web Solutions for a Strong Online Impact
                    </p>
                    <p className="leading-4 hover-visibility slide-left xsm:text-[8px] xsm:leading-none md:text-[12px] md:leading-none">
                    We deliver custom, responsive, and SEO-friendly websites that perfectly align with your brand and business objectives.
                    </p>
                  </div>
                  {/* div-2 */}
                  <div
                    className="flex flex-col justify-end p-[5%] hover-parent hover:bg-black hover:bg-opacity-20 transition duration-300 overflow-hidden"
                    onMouseLeave={() => {
                      setHEADING(heading + 5);
                    }}
                    onMouseEnter={() => handleHover(Img2, 2)}
                  >
                    <p
                      className={`text-[24px] font-semibold capitalize leading-6 xsm:text-[10px] xsm:leading-none md:text-[16px] md:leading-none ${
                        heading == 2 ? "slide-top" : "slide-bottom"
                      } `}
                    >
                      Streamline Operations with Custom Software
                    </p>
                    <p className="leading-4 hover-visibility slide-left xsm:text-[8px] xsm:leading-none md:text-[12px] md:leading-none">
                    Our tailored software solutions are built to enhance productivity and scalability, fitting seamlessly into your business processes.
                    </p>
                  </div>
                  {/* div-3 */}
                  <div
                    className="flex flex-col justify-end p-[5%] hover-parent hover:bg-black hover:bg-opacity-20 transition duration-300 overflow-hidden"
                    onMouseLeave={() => {
                      setHEADING(heading + 5);
                    }}
                    onMouseEnter={() => handleHover(Img3, 3)}
                  >
                    <p
                      className={`text-[24px] font-semibold capitalize leading-6 xsm:text-[10px] xsm:leading-none md:text-[16px] md:leading-none ${
                        heading == 3 ? "slide-top" : "slide-bottom"
                      } `}
                    >
                      Engaging Mobile Apps for All Platforms
                    </p>
                    <p className="leading-4 hover-visibility slide-left xsm:text-[8px] xsm:leading-none md:text-[12px] md:leading-none">
                    We develop user-friendly mobile apps for iOS and Android, designed to maximize user engagement and retention.
                    </p>
                  </div>
                  {/* div-4 */}
                  <div
                    className="flex flex-col justify-end p-[5%] hover-parent hover:bg-black hover:bg-opacity-20 transition duration-300 overflow-hidden"
                    onMouseLeave={() => {
                      setHEADING(heading + 5);
                    }}
                    onMouseEnter={() => handleHover(Img4, 4)}
                  >
                    <p
                      className={`text-[24px] font-semibold capitalize leading-6 xsm:text-[10px] xsm:leading-none md:text-[16px] md:leading-none ${
                        heading == 4 ? "slide-top" : "slide-bottom"
                      } `}
                    >
                      Maximize Online Visibility and Growth
                    </p>
                    <p className="leading-4 hover-visibility slide-left xsm:text-[8px] xsm:leading-none md:text-[12px] md:leading-none">
                    Our targeted marketing strategies, including SEO, SEM, and content marketing, are designed to increase traffic and promote growth.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </GridLines>
      </div>
    </main>
  );
};

export default Services;
