import React from 'react'
import PR1img1 from '../../Assests/Images/Project1img4.png'
import PR1img2 from '../../Assests/Images/Project1img5.png'
import PR1img3 from '../../Assests/Images/Project1img6.png'
import PR1img4 from '../../Assests/Images/Project1img7.png'
import PR2img1 from "../../Assests/Images/Landing Home 2.png";
import PR2img2 from "../../Assests/Images/HOME2.png";
import PR2img3 from "../../Assests/Images/Main Page.png";
import PR2img4 from "../../Assests/Images/-img.png";
import ProjectCard from './ProjectCard'

const ProjectsSection = ({ category }) => {

    const categories = {
        FEATURED: {
          name: "FEATURED",
          projects: [
            {
                name: "Sheffield Recovery",
                image1: PR1img1,
                image2: PR1img1,
                image3: PR1img1,
                type: "Website app",
            },
            {
                name: "Startup Innovations ",
                image1: PR1img2,
                image2: PR1img2,
                image3: PR1img2,
                type: "Website app",
            },
            {
                name: "Corporate Solutions",
                image1: PR1img3,
                image2: PR1img3,
                image3: PR1img3,
                type: "Website app",
            },
            {
                name: "Consumer Experiences ",
                image1: PR1img4,
                image2: PR1img4,
                image3: PR1img4,
                type: "Website app",
            },
          ],
        },
        B2B: {
          name: "B2B",
          projects: [
            {
                name: "Sheffield Recovery",
                image1: PR2img1,
                image2: PR2img1,
                image3: PR2img1,
                type: "Website app",
            },
            {
                name: "Startup Innovations ",
                image1: PR2img2,
                image2: PR2img2,
                image3: PR2img2,
                type: "Website app",
            },
            {
                name: "Corporate Solutions",
                image1: PR2img3,
                image2: PR2img3,
                image3: PR2img3,
                type: "Website app",
            },
            {
                name: "Consumer Experiences ",
                image1: PR2img4,
                image2: PR2img4,
                image3: PR2img4,
                type: "Website app",
            },
          ],
        },
        B2C: {
          name: "B2C",
          projects: [
            {
                name: "Sheffield Recovery",
                image1: PR1img1,
                image2: PR1img1,
                image3: PR1img1,
                type: "Website app",
            },
            {
                name: "Startup Innovations ",
                image1: PR1img2,
                image2: PR1img2,
                image3: PR1img2,
                type: "Website app",
            },
            {
                name: "Corporate Solutions",
                image1: PR1img3,
                image2: PR1img3,
                image3: PR1img3,
                type: "Website app",
            },
            {
                name: "Consumer Experiences ",
                image1: PR1img4,
                image2: PR1img4,
                image3: PR1img4,
                type: "Website app",
            },
          ],
        },
        ECOMMERCE: {
          name: "Ecommerce",
          projects: [
            {
                name: "Sheffield Recovery",
                image1: PR2img1,
                image2: PR2img1,
                image3: PR2img1,
                type: "Website app",
            },
            {
                name: "Startup Innovations ",
                image1: PR2img2,
                image2: PR2img2,
                image3: PR2img2,
                type: "Website app",
            },
            {
                name: "Corporate Solutions",
                image1: PR2img3,
                image2: PR2img3,
                image3: PR2img3,
                type: "Website app",
            },
            {
                name: "Consumer Experiences ",
                image1: PR2img4,
                image2: PR2img4,
                image3: PR2img4,
                type: "Website app",
            },
          ],
        },
    };
    
    const selectedCategory = categories[category];

    return (
        <div className='flex gap-16 px-[12%] pt-[5%] font-pop xsm:px-[3%] md:px-[5%]'>
            {selectedCategory &&(
                <div className='flex gap-16 xsm:gap-6 md:gap-12'>
                    {/* 1 3 */}
                    <div className='flex w-[50%] flex-col gap-12 xsm:gap-4 md:gap-8'>
                        {/* 1 */}
                        <ProjectCard Img1={selectedCategory.projects[0].image1} Img2={selectedCategory.projects[0].image2} Img3={selectedCategory.projects[0].image3} title={selectedCategory.projects[0].name} type={selectedCategory.projects[0].type}/>
                        {/* 3 */}
                        <ProjectCard Img1={selectedCategory.projects[1].image1} Img2={selectedCategory.projects[1].image2} Img3={selectedCategory.projects[1].image3} title={selectedCategory.projects[1].name} type={selectedCategory.projects[1].type}/>
                    </div>
                    {/* 2 4 */}
                    <div className='flex w-[50%] flex-col gap-12 mt-[11%] xsm:gap-4 '>
                        {/* 2 */}
                        <ProjectCard Img1={selectedCategory.projects[2].image1} Img2={selectedCategory.projects[2].image2} Img3={selectedCategory.projects[2].image3} title={selectedCategory.projects[2].name} type={selectedCategory.projects[2].type}/>
                        {/* 3 */}
                        <ProjectCard Img1={selectedCategory.projects[3].image1} Img2={selectedCategory.projects[3].image2} Img3={selectedCategory.projects[3].image3} title={selectedCategory.projects[3].name} type={selectedCategory.projects[3].type}/>
                    </div>
                </div>
            )}
        </div>
    )
}

export default ProjectsSection