import { useEffect, useRef } from "react";
import GridLines from "react-gridlines";
import gsap from "gsap";
import "../Animation/Glow.css";
import ServiceHero from "./ServiceHero";
import AllServices from "./AllServices";
import DevelopmentProcess from "./DevelopmentProcess";
import Reviews from "./Reviews";
import Whychooseus from "../WhyChoose/Whychoose";
import { Helmet } from "react-helmet";

const OurServices = () => {

  const roundRef = useRef();
  useEffect(() => {
    const main = document.getElementById("main");
    const round = document.getElementById("round");
    main.addEventListener("mouseenter", function () {
      gsap.to(round, {
        opacity: 0.5,
        scale: 1,
      });
    });
    main.addEventListener("mouseleave", function () {
      gsap.to(round, {
        scale: 0,
        opacity: 0,
      });
    });
    main.addEventListener("mousemove", function (dets) {
      gsap.to(round, {
        left: dets.x - 120,
        top: dets.y - 120,
      });
    });
  }, []);

  return (
    <main id="main">
      <Helmet>
        <title>HM CodeLab - Services</title>
        <meta name="description" content="Discover bespoke digital solutions at HM CodeLab. We specialize in web development, mobile apps, UI/UX design, digital marketing, and e-commerce solutions. Transform your vision into reality with our expertise. Visit HM CodeLab Services to learn more and get started on your tech journey." data-react-helmet="true"/>
        <meta property="og:title" content="HM CodeLab - Services" data-react-helmet="true"/>
        <meta property="og:type" content="website" data-react-helmet="true"/>
        <meta property="og:image" content="/og_image_services.png" data-react-helmet="true"/>
        <meta property="og:image:width" content="526" data-react-helmet="true"/>
        <meta property="og:image:height" content="275" data-react-helmet="true"/>
        <meta property="og:image:type" content="image/png" data-react-helmet="true"/>
        <meta name="twitter:card" content="summary_large_image" data-react-helmet="true"/>
        <meta property="og:description" content="Discover bespoke digital solutions at HM CodeLab. We specialize in web development, mobile apps, UI/UX design, digital marketing, and e-commerce solutions. Transform your vision into reality with our expertise. Visit HM CodeLab Services to learn more and get started on your tech journey." data-react-helmet="true"/>
        <meta property="og:site_name" content="HM CodeLab - Services" data-react-helmet="true"/>
      </Helmet>
      <span id="round" ref={roundRef}></span>
      <div className="bg-black text-white">
        <GridLines
          strokeWidth={4}
          lineColor="#1B1E2D80"
          cellWidth={50}
          cellHeight={42}
        >
          <div className="gridglow">
            <ServiceHero />
            <AllServices />
            <DevelopmentProcess />
            <Reviews />
            <Whychooseus />
          </div>
        </GridLines>
      </div>
    </main>
  );
};

export default OurServices;
